import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDebounce, useForm, useGet, useModelData } from '../../Hooks'
import Button from '../button/Button'
import SearchInput from '../input/SearchInput'
import Table from '../table/Table'
import Slider from '../wrap/Slider'
import SettingsModal from './SettingsModal'

const setParams = (formValues, base_url) => {
  if (base_url == null) return
  const params = new URLSearchParams(formValues)
  const url = new URL(base_url)
  url.search = params.toString()
  return url
}

const BaseSettings = ({ modelData }) => {
  const [items, setItems] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalAction, setModalAction] = useState(null)

  const [getData, setGetData] = useState({ url: null })
  const getState = useGet(getData)
  const [selectedItem, setSelectedItem] = useState({})

  const [
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormValuesByName
  ] = useForm({ search: '' })

  const debouced = useDebounce(formValues.search, 300)

  const showHandler = () => {
    setSelectedItem({})
    setModalAction(null)
    setShowModal(false)
  }

  const rowCallback = (id) => {
    const selected = items.find(obj => obj.id === id)
    setSelectedItem(selected)
    setShowModal(true)
  }

  const modalCallback = (newObj) => {
    if (typeof newObj === 'object') {
      setItems(old => {
        let newItems
        if (modalAction === 'create') {
          old.push(newObj)
          newItems = [...old]
        } else {
          newItems = old.map(obj => {
            if (obj.id === newObj.id) {
              return newObj
            } else {
              return obj
            }
          })
        }

        return newItems
      })
    } else {
      setItems(old => {
        const newItems = old.filter(obj => obj.id != newObj)
        return newItems
      })
    }
    showHandler(false)
  }

  useLayoutEffect(() => {
    const url = setParams(formValues, modelData.url)
    setGetData({ url })
  }, [debouced, modelData.url])

  useLayoutEffect(() => {
    setGetData({ url: modelData.url })
    return () => {
      setGetData({ url: null })
    }
  }, [])

  useEffect(() => {
    if (getState.data != null) {
      setItems(getState.data)
    }
  }, [getState.data])

  return (

    <Slider
      header={modelData.header}
    >

      <section
        className=' flex flex-col gap-7 justify-between  p-3 border-b  border-b-stone-700 '
      >

        <form
          className='max-w-[300px]'
        >
          <SearchInput
            formValues={formValues}
            setValue={null}
            setValueByEvent={setFormValuesByEvent}
            name='search'
          />
        </form>
        <Table
          items={items}
          rowCallback={rowCallback}
          type={modelData.type}
        />


        <div>

          <Button
            text={`Aggiungi ${modelData.label}`}
            onclick={() => {
              setModalAction('create')
              setShowModal(true)
            }}
          />

        </div>
      </section>
      {
        showModal === true &&
          <SettingsModal
            showHadler={showHandler}
            item={selectedItem}
            respCallback={modalCallback}
            modelData={modelData}
            action={modalAction}
          />
      }
    </Slider>
  )
}

export default BaseSettings
