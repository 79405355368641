import { useDebounce, useForm } from '../../Hooks'
import Select from '../input/Select'
import SearchInput from '../input/SearchInput'

import useSessionStorageState from 'use-session-storage-state'
import Slider from '../wrap/Slider'
import { useEffect } from 'react'
import Button from '../button/Button'




const initialValues = {
    search: '',
    famiglia: null,
    famiglia_prodotto: null,
    um: null
  }

const AnagraficaFilter = ({ filterCallback }) => {
  const [
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormValuesByName
  ] = useForm(initialValues)


  const debValue = useDebounce(formValues, 400)

  useEffect(() => {
    filterCallback(debValue)
  }, [debValue])

  return (

    <div
      className='w-[90vw]'
    >

      <div 
        className = "flex gap-6 items-end"
      >
      <SearchInput

        formValues={formValues}
        setValue={null}
        setValueByEvent={setFormValuesByEvent}
        name='search'

      />


        <Button
          text= "cancella filtri"
          onclick={ () => setFormValues(initialValues)}
          />

      </div>
      <Slider
        header='FILTRI'
      >

        <div
          className='relative flex flex-wrap gap 5'
        >

          <Select
            formValues={formValues}
            setValue={setFormValuesByEvent}

            name='famiglia'
          />
          <Select
            formValues={formValues}
            setValue={setFormValuesByEvent}
            name='famiglia_prodotto'
          />
          <Select
            formValues={formValues}
            setValue={setFormValuesByEvent}
            name='um'
          />

        </div>

      </Slider>

    </div>
  )
}

export default AnagraficaFilter
