import { useMemo } from 'react'




const SearchDropdown = ({ items, type, itemCallback }) => {
  const itemContent = (item) => {

    switch (type) {
      case 'anagrafica':
        return (
          <>
            <p><span className="font-bold">Cod.:</span> {item['cod. prodotto']}</p>
            <p><span className="font-bold">Desc.: </span> {item.descrizione}</p>
            <p><span className="font-bold">Fam.: </span> {item.famiglia}</p>
            <p><span className="font-bold">Fam. Pr.:</span> {item['famiglia prodotto']}</p>
            <p><span className="font-bold">UM:</span> {item['unità di misura']}</p>
          </>
        )
      default:
        return 'Sconosciuto'
    }
  }

  const renderItems = useMemo(() => {
    return items.map((obj, idx) => {
      return (

        <li
          key={`${idx}_${obj.id}`}
          className=' text-sm text-start pl-3 py-3 border-b border-b-black w-full max-w-[300px]'
          onClick={() => itemCallback(obj)}
        >
          {itemContent(obj)}

        </li>
      )
    })
  }, [items, type])





  return (

    <ul
      className='flex flex-col items-start fixed max-h-[500px] overflow-y-auto bg-lime-50  w-fit z-20'
    >
      {renderItems}

    </ul>
  )
}

export default SearchDropdown
