
import './SmallLoader.css'




const SmallLoader = () => {




  return <span className="small-loader"></span>

}



export default SmallLoader
