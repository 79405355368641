import { createContext, useEffect, useState } from "react";

export const DataContext = createContext({});

export const DataContextProvider = ({ children }) => {
  const [appData, setAppData] = useState({});

  //debug
  useEffect( ()  => {

    console.log("appData",appData)
  },[appData])

  return (
    <DataContext.Provider value={[appData, setAppData]}>
      {children}
    </DataContext.Provider>
  );
};
