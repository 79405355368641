import { IoIosArrowDown } from 'react-icons/io'
import { useEffect, useRef, useState } from 'react'

const Slider = ({ children, header }) => {
  const sliderRef = useRef()


  const toggleSlider = () => {
    const slider = sliderRef.current

    if (slider.classList.contains('slider-active')) {
      slider.style.height = '0px'
    } else {
      const child = slider.firstChild
      const height = child.clientHeight
      slider.style.height = `${height}px`
    }
    sliderRef.current.classList.toggle('slider-active')
  }


  useEffect( () => {

    const slider = sliderRef.current

    if (slider.classList.contains('slider-active')) {
      const child = slider.firstChild
      const height = child.clientHeight
      slider.style.height = `${height}px`
    }

  }, [children])

  return (
    <div

      className='relative p-3 w-full border-b border-b-black'
    >

      <div
        className='flex justify-start items-center gap-4  left-0 cursor-pointer w-full'
        onClick={toggleSlider}
      >

        <h2>{header}</h2>

        <i className='text-2xl'>
          <IoIosArrowDown />
        </i>

      </div>

      <div
        ref={sliderRef}
        className='transition-all overflow-hidden h-0'
      >

        {children}
      </div>

    </div>
  )
}

export default Slider
