import { useEffect, useRef, useState } from 'react'
import { useForm } from '../../Hooks'
import Select from '../input/Select'
import TextInput from '../input/TextInput'
import SearchInput from '../input/SearchInput'
// import MovimentoModal from './MovimentoModal'
import DigitInput from '../input/DigitInput'
import CheckBox from '../input/CheckBox'
import DateInput from '../input/DateInput'

const MovimentoForm = ({ 
  formValues, 
  setFormValuesByEvent, 
  setFormValuesByName, 
  selectedParent ,
  optionCausaleCallback,
}) => {
  const initialRef = useRef(true)

  const [semaphore, setSemaphore] = useState({
    causale_id: false
  })

  // useEffect(() => {
  //   if (Object.values(semaphore).every(bool => bool) && initialRef.current === true && values.id !== '') {
  //     initialRef.current = false
  //     setFormValues(values)
  //   }
  // }, [values, semaphore])

  // useEffect(() => {
  //   setValues(formValues)
  // }, [formValues])

  return (

    <form
      className='flex flex-wrap gap-3 overflow-y-auto h-full'
    >

      <DateInput
        name='data'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        label='Data movimento'
      />


      <Select
        name='causale_id'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        setSemaphore={setSemaphore}
        optionCallback={optionCausaleCallback}
      />


    </form>
  )
}

export default MovimentoForm

// <Select
//   name='scelta_id'
//   formValues={formValues}
//   setValue={setFormValuesByEvent}
//   setSemaphore={setSemaphore}
// />
// <TextInput
//   name='id_tag'
//   formValues={formValues}
//   setValue={setFormValuesByEvent}
//   placeholder='ID Tag'
// />
