import { useCallback, useMemo, useRef } from 'react'
import SidebarItem from './SidebarItem'
import './Sidebar.css'

import { RxHamburgerMenu } from 'react-icons/rx'
import { useLocation } from 'react-router-dom'
import { LOGO_URL } from '../../Costants'

const MENU = [

  {
    text: 'CARICHI',
    href: 'carichi'
  },

  {
    text: 'INVENTARIO',
    href: 'inventario'
  },
  {
    text: 'GIACENZE',
    href: 'giacenze'
  },
  {
    text: 'ANAGRAFICHE',
    href: 'anagrafiche'
  },
  {
    text: 'MOVIMENTI MAGAZZINO',
    href: 'movimenti'
  },
   {
    text: 'DOWNLOAD INVENTARI',
    href:"download_inventari" ,
  },
{
    text: 'SETTINGS',
    href: 'settings'
  },

  {
    text: 'LOGOUT',
    href: ''
  }
]

const Sidebar = () => {
  const sidebarRef = useRef()

  const location = useLocation()

  const toggleSidebar = useCallback(() => {
    sidebarRef.current.classList.toggle('sidebar-active')
  }, [])

  const menu = useMemo(() => {
    return MENU.map((item, idx) => {
      return (
        <SidebarItem
          item={item}
          key={idx}
          toggleSidebar={toggleSidebar}
        />
      )
    })
  }, [])

  if (location.pathname.includes('login')) { return null } else {
    return (

      <div
        className='sidebar-wrap bg-stone-500 h-[70px] fixed top-0 left-0 w-full flex items-center justify-center rounded-bl-xl rounded-br-xl '
        style={{
          boxShadow: '0px 3px 20px 0px black'
        }}
      >

        <aside
          className='sidebar bg-stone-500'
          ref={sidebarRef}
        >
          <div>
            <img
              src={LOGO_URL.href}
              alt='logo'
            />
          </div>

          <ul>
            {menu}
          </ul>

        </aside>

        <div
          className='sidebar-bg fixed top-0 left-0 w-[100vw] h-[100vh] bg-black opacity-50 '
          onClick={toggleSidebar}
        />

        <h1
          className='text-amber-500 text-2xl'
        >{location.pathname.toUpperCase().split('/')[1].replaceAll("_"," " )
        }
        </h1>

        <i
          onClick={toggleSidebar}
          className='text-white'
        >

          <RxHamburgerMenu />

        </i>

      </div>
    )
  }
}

export default Sidebar
