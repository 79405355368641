import { Route, Routes } from 'react-router-dom'
import './App.css'
import { useAuthCheck } from './components/Hooks'
import AnagraficheArticoli from './components/pages/AnagraficheArticoli'
import LoginPage from './components/pages/LoginPage'
import CaricoPage from './components/pages/CaricoPage'
import SettingsPage from './components/pages/SettingsPage'
import Sidebar from './components/views/sidebar/Sidebar'
import CaricoFormPage from './components/pages/CaricoFormPage'
import InventarioPage from './components/pages/InventarioPage'
import MovimentiPage from './components/pages/MovimentiPage'
import GiacenzePage from './components/pages/GiacenzePage'
import MovimentoFormPage from './components/pages/MovimentoFormPage'
import CambioMagazzinoFormPage from './components/pages/CambioMagazzinoFormPage'
import DownloadInventariPage from './components/pages/DownloadInventariPage'

function App () {
  useAuthCheck()
  return (
    <>
      <Routes>
        <Route path='*' element='' />
        <Route path='/anagrafiche' element={(<AnagraficheArticoli />)} />
        <Route path='/carichi' element={(<CaricoPage />)} />
        <Route path='/inventario' element={(<InventarioPage />)} />
        <Route path='/movimenti' element={(<MovimentiPage />)} />
        <Route path='/giacenze' element={(<GiacenzePage />)} />
        <Route path='/carico/:action' element={(<CaricoFormPage />)} />
        <Route path='/movimento/:action' element={(<MovimentoFormPage />)} />
        <Route path='/settings' element={(<SettingsPage />)} />
        <Route path='/login' element={(<LoginPage />)} />
        <Route path='/cambio_magazzino' element={(<CambioMagazzinoFormPage/>)} />
        <Route path='/download_inventari' element={(<DownloadInventariPage/>)} />
      </Routes>
      <Sidebar />

    </>
  )
}

export default App
