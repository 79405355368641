import { useEffect, useMemo, useState } from "react";
import { CARICO_URL } from "../../Costants";
import { useDebounce, useForm, useGet, usePost } from "../../Hooks";
import Button from "../button/Button";
import DigitInput from "../input/DigitInput";
import Select from "../input/Select";
import Modal from "../wrap/Modal";
import CaricoRow from "./CaricoRow";

const SpostaCavallettoModal = ({ showHandler }) => {
  const [formValues, setFormValues, setFormValuesByEvent, setFormValuesByName] =
    useForm({
      numero_cavalletto: "",
      magazzino_id: "",
    });

  const [foundedCarichi, setFoundedCarichi] = useState([]);

  const [postData, setPostData] = useState({ url: null, query: null });
  const postState = usePost(postData);

  const [getData, setGetData] = useState({ url: null });
  const getState = useGet(getData);

  const debNumCavalletto = useDebounce(formValues.numero_cavalletto, 300);

  const renderCarichi = useMemo(() => {
    return foundedCarichi.map((car, idx) => {
      return <CaricoRow carico={car} key={`${idx}_${car.id}`} />;
    });
  }, [foundedCarichi]);

  useEffect(() => {
    const url = new URL(CARICO_URL);
    const searchParams = new URLSearchParams({
      n_cavalletto: debNumCavalletto,
    });
    url.search = searchParams.toString();
    setGetData({ url });
  }, [debNumCavalletto]);

  useEffect(() => {
    if (getState.data != null) {
      setFoundedCarichi(getState.data);
    }
  }, [getState.data]);

  return (
    <Modal showHadler={showHandler}>
      <div className="flex flex-col justify-center items-center gap-3 h-full ">
        <h2 className="text-xl">Sposta carichi</h2>

        <form className="flex justify-center">
          <DigitInput
            name="numero_cavalletto"
            formValues={formValues}
            setValue={setFormValuesByEvent}
            placeholder="Numero cavalletto"
          />
        </form>

        <hr className="bg-black text-black border border-black w-full" />
        <ul className="w-full max-h-[70vh] overflow-y-auto">{renderCarichi}</ul>
      </div>
    </Modal>
  );
};

// <Select
//            name="magazzino_id"
//            formValues={formValues}
//            setValue={setFormValuesByEvent}
//          />

export default SpostaCavallettoModal;
