import { useNavigate } from 'react-router-dom'
import { useLogout } from '../../Hooks'
// import SidebarNestedItem from './SidebarNestedItem'

const SidebarItem = ({ item, toggleSidebar, nested = false }) => {
  const navigate = useNavigate()
  const doLogout = useLogout()

  const click = () => {
    toggleSidebar()
 		navigate(item.href)
  }

  // if (nested === true) {
  //   return (
  //     <SidebarNestedItem />
  //   )
  // } else {
    return (
      <li

        className='text-white  active:text-amber-500'
        onClick={() => {
			  if (item.text !== 'LOGOUT') {
			    click()
			  } else {
			    doLogout()
			  }
        }}
      >
        {item.text}
      </li>

    )
  // }
}

export default SidebarItem
