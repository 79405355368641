const TableItem = ({
  content,
  isSmall,
  isMidium,
  isDark,
  isHeader,
  isRounded,
  stato = null
}) => {
  const possibleClasses = [
    'bg-orange-400',
    'bg-orange-300',
    'w-[110px]',
    'w-[160px]',
    'w-[250px]',
    'bg-stone-400',
    'bg-yellow-300',
    'bg-green-400'
  ]

  const classes = () => {
    let cls = ''
    if (isHeader) {
      cls += 'bg-stone-400 '
    } else {
      switch (stato?.toLowerCase()) {
        case 'lavorazione':
          cls += 'bg-yellow-300 '
          break
        case 'venduto':
          cls += 'bg-green-400 '
          break

        default:
          if (isDark) {
            cls += 'bg-orange-400 '
          } else {
            cls += 'bg-orange-300 '
          }
          break
      }
    }

    if (isSmall) {
      cls += 'w-[110px] '
    } else if (isMidium) {
      cls += 'w-[160px] '
    } else {
      cls += 'w-[250px] '
    }

    return cls
  }

  return (

    <td
      className={`${classes()} border-r px-4 py-2  border-black break-words `}
    >{isRounded && !isHeader ? Number(content).toFixed(2) : content}
    </td>
  )
}

export default TableItem
