import TextInput from '../input/TextInput'
import Select from '../input/Select'
import ModalBtnGroup from '../misc/ModalBtnGroup'
import Modal from '../wrap/Modal'
import { useDelete, useGet, useModelData, usePost, usePut } from '../../Hooks'
import { useContext, useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react'
import AnagraficaModalForm from './AnagraficaModalForm'
import { ErrorContext } from '../../context/ErrorContex'
import Button from '../button/Button'
import Loader from '../loader/Loader'
import { useNavigate } from 'react-router-dom'

const requestReducer = (old, action) => {
  switch (action.key) {
    case 'SET_UP':
      return {
        ...old,
        tmp_url: action.url
      }
    case 'UPDATE':
      return {
        ...old,
        update_url: action.url,
        body: action.body
      }
    case 'DELETE':
      return {
        ...old,
        delete_url: action.url
      }

    case 'GET':
      return {
        ...old,
        get_url: action.url
      }

    case 'CREATE':
      return {
        ...old,
        create_url: old.tmp_url,
        body: action.body
      }

    default:
      return old
  }
}

const initailReducerValue = {
  tmp_url: null,
  update_url: null,
  delete_url: null,
  create_url: null,
  get_url: null,
  body: null
}

const AnagraficaModal = ({ item, showHandler, action, respCallback, switchModal }) => {
  const modelData = useModelData('anagrafica')


  const navigate = useNavigate()

  const [reqState, dispatch] = useReducer(requestReducer, initailReducerValue)

  const [values, setValues] = useState({})
  const [, setErrors] = useContext(ErrorContext)

  const putState = usePut({ url: reqState.update_url, query: reqState.body })
  const delState = useDelete({ url: reqState.delete_url })
  const postState = usePost({ url: reqState.create_url, query: reqState.body })
  const getState = useGet({ url: reqState.get_url })
  const recivedRef = useRef(false)

  const create = () => {
    dispatch({ key: 'CREATE', body: { ...values } })
  }

  const update = () => {
    const url = new URL(item, modelData.url)
    dispatch({ key: 'UPDATE', body: { ...values }, url })
  }
  const del = () => {
    dispatch({ key: 'DELETE', url: new URL(item, reqState.tmp_url) })
  }

  useEffect(() => {
    if (putState.data != null) {
      respCallback(putState.data)
    } else if (postState.data != null) {
      respCallback(postState.data)
    } else if (delState.data != null) {
      respCallback(delState.data)
    } else if (getState.data != null) {
      dispatch({ key: 'SET_UP', url: modelData.url })
      setValues(getState.data)
      recivedRef.current = true
    }
  }, [putState.data, postState.data, delState.data, getState.data])

  useEffect(() => {
    if (putState.error != null) {
      setErrors(putState.error)
    } else if (postState.error != null) {
      setErrors(postState.error.data)
    } else if (delState.error != null) {
      setErrors(delState.error)
    }
  }, [putState.error, postState.error, delState.error, getState.error])

  useEffect(() => {
    return () => {
      setErrors({})
    }
  }, [])

  useEffect(() => {
    if (action === 'create') {
      dispatch({ key: 'SET_UP', url: modelData.url })
      return
    }
    if (modelData.type == null) return

    const url = new URL(item, modelData.url)
    dispatch({ key: 'GET', url })
  }, [modelData.type, item])



        // {
        //   action !== 'create' ?
        //
        //
        //     recivedRef.current === true  ?
        //       <AnagraficaModalForm
        //         values={values}
        //         setValues={setValues}
        //       />:
        //       <Loader/>
        //
        //
        //   :

  return (
    <Modal
      showHadler={showHandler}
    >

      <div
        className='flex flex-col justify-center items-center gap-4'
      >

        <h2 className='text-xl'>{
          action === 'create' ? `Crea nuova ${modelData.single_header}` : `${modelData.single_header} ${item}`
        }
        </h2>



        <AnagraficaModalForm
          values={values}
          setValues={setValues}
        />

        <div
          className='flex justify-around w-full'
        >

          <ModalBtnGroup

            isLoading={delState.loading || postState.loadint || putState.loading}
            delCallback={del}
            updateCallback={update}
            createCallback={create}
            action={action}

          />

          {
            !( delState.loading || postState.loadint || putState.loading ) && action !== 'create' &&
              <Button
                text='Crea carico'
                onclick={()=> navigate("/carico/add", { state:{
                  cod_prodotto: values.cod_prodotto
                }})}
              />
          }

        </div>
      </div>

    </Modal>
  )
}

// {
//   error != null &&
//     <ErrorLabel error={error}/>
// }

export default AnagraficaModal
