import ModalBtnGroup from '../views/misc/ModalBtnGroup'
import { getValueFromItem, useDelete, useForm, useGet, useModelData, usePost, usePut } from '../Hooks'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import MovimentoForm from '../views/movimenti/MovimentoForm'
import { ErrorContext } from '../context/ErrorContex'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DeleteModal from '../views/wrap/DeleteModal'
import Slider from '../views/wrap/Slider'
import PelleDetails from '../views/inventario/PelleDetails'

const requestReducer = (old, action) => {
  switch (action.key) {
    case 'SET_UP':
      return {
        ...old,
        tmp_url: action.url
      }
    case 'UPDATE':
      return {
        ...old,
        update_url: action.url,
        body: action.body
      }
    case 'DELETE':
      return {
        ...old,
        delete_url: action.url
      }

    case 'GET':
      return {
        ...old,
        get_url: action.url
      }

    case 'CREATE':
      return {
        ...old,
        create_url: old.tmp_url,
        body: action.body
      }

    default:
      return old
  }
}

const initailReducerValue = {
  tmp_url: null,
  update_url: null,
  delete_url: null,
  create_url: null,
  get_url: null,
  body: null
}

const DELETE_MODAL_TEXT = 'Stai per eliminare il movimento selezionato questo postrebbe creare delle incongruenze con le giacenze, vuoi continuare?'

const MovimentoFormPage = () => {
  const { action } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormValuesByName
  ] = useForm({
    id: '',
    pelle_id: '',
    data: new Date().toISOString().split('T')[0],
    causale_id: ''
  })

  const modelData = useModelData('movimento_magazzino')
  const [showDeleteModal, setShowDeleteModal] = useState()
  const [reqState, dispatch] = useReducer(requestReducer, initailReducerValue)
  const [, setErrors] = useContext(ErrorContext)

  const putState = usePut({ url: reqState.update_url, query: reqState.body })
  const delState = useDelete({ url: reqState.delete_url })
  const postState = usePost({ url: reqState.create_url, query: reqState.body })
  const getState = useGet({ url: reqState.get_url })

  const genDetailModelUrl = () => {
    const item_id = location.state.id
    return new URL(item_id, modelData.url)
  }

  const create = () => {
    const body = {
      ...formValues
    }
    dispatch({ key: 'CREATE', body })
  }

  const update = () => {
    const url = genDetailModelUrl()
    const body = {
      ...formValues
    }
    dispatch({ key: 'UPDATE', body, url })
  }

  const del = () => {
    const url = genDetailModelUrl()
    dispatch({ key: 'DELETE', url })
  }

  useEffect(() => {
    if (putState.data != null) {
      navigate(location?.state?.from)
    } else if (postState.data != null) {
      navigate(location?.state?.from)
    } else if (delState.data != null) {
      navigate(location?.state?.from)
    } else if (getState.data != null) {
      dispatch({ key: 'SET_UP', url: modelData.url })
      const values = getState.data
      setFormValues(values)
    }
  }, [putState.data, postState.data, delState.data, getState.data, location?.state?.from ])

  const handleErrors = (errData, errType) => {
    setErrors(errData ?? {})
  }

  useEffect(() => {
    if (putState.error != null) {
      const errData = putState.error.data
      const errType = putState.error.type
      handleErrors(errData, errType)
    } else if (postState.error != null) {
      const errData = postState.error.data
      const errType = postState.error.type
      handleErrors(errData, errType)
    } else if (delState.error != null) {
      setErrors(delState.error)
    } else if (getState.error != null) {
      // setValues(getState.error)
    }
  }, [putState.error, postState.error, delState.error, getState.error])

  useEffect(() => {
    if (action === 'add') {
      dispatch({ key: 'SET_UP', url: modelData.url })
      return
    }
    if (modelData.type == null) return

    const item_id = location.state.id
    const url = new URL(item_id, modelData.url)
    dispatch({ key: 'GET', url })
  }, [
    action,
    modelData.type
    // item
  ])
  //
  useEffect(() => {
    return () => {
      setErrors({})
    }
  }, [])

  const optionCausaleCallback = (values) => {
    switch (location.state.pelle?.stato.toLowerCase()) {
      case 'magazzino':
        return values.filter(opt => {
          const text = getValueFromItem(opt)
          return text.includes('in lavorazione') || text === 'venduto'
        })
      case 'lavorazione':
        return values.filter(opt => {
          const text = getValueFromItem(opt)
          return text === 'rientro da lavorazione' || text === 'da lavorazione a venduto' 
        })
      case 'venduto':
        return values.filter(opt => {
          const text = getValueFromItem(opt)
          return text === 'carico'
        })
      default:
        return values
    }
  }

  useEffect(() => {
    const pelle = location.state.pelle
    if (pelle != null) {
      setFormValuesByName('pelle_id', pelle.id)
    }
  }, [location.state.pelle?.id])

  return (
    <main>

      <div
        className='flex flex-col justify-center items-center gap-5 h-full pb-5'
      >

        <h2 className='text-xl'>{
          action === 'add' ? `Crea nuovo ${modelData.single_header}` : `${modelData.single_header} ${location.state.id}`
        }
        </h2>






        <MovimentoForm
          formValues={formValues}
          setFormValuesByName={setFormValuesByName}
          setFormValuesByEvent={setFormValuesByEvent}
          optionCausaleCallback={optionCausaleCallback}
        />

        <div
          className='flex justify-around w-full mt-5'
        >

          <ModalBtnGroup

            isLoading={delState.loading || postState.loading || putState.loading}
            delCallback={() => setShowDeleteModal(true)}
            updateCallback={update}
            createCallback={create}
            action={action === 'add' ? 'create' : action}
          />

        </div>
      </div>

      {
        showDeleteModal === true &&
          <DeleteModal

            closeModal={() => setShowDeleteModal(false)}
            deleteCallback={del}
            text={DELETE_MODAL_TEXT}
          />
      }

    </main>

  )
}

export default MovimentoFormPage



        // <Slider
        //
        //   header="Dettagli"
        //
        // >
        //
        //
        //   <PelleDetails
        //     data={location.state?.pelle}
        //     horizontal={true}
        //   />
        //
        //
        // </Slider>
