import ModalBtnGroup from '../views/misc/ModalBtnGroup'
import { getValueFromItem, useForm, useGet, useModelData, usePost, usePut } from '../Hooks'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import CambioMagazzinoForm from '../views/movimenti/CambioMagazzinoForm'
import { ErrorContext } from '../context/ErrorContex'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import InfoModal from '../views/wrap/InfoModal'
import Slider from '../views/wrap/Slider'
import PelleDetails from '../views/inventario/PelleDetails'
import Button from '../views/button/Button'

const INFO_MODAL_TEXT = 'ERRORE: nessuna pelle selezionata, toranre all inventario e ripetere'

const CambioMagazzinoFormPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormValuesByName
  ] = useForm({
    pelle: {},
    magazzino_id: '',
    ubicazione_scaffale: '',
    numero_cavalletto: ''
  })

  const modelData = useModelData('pelle')
  const [showInfoModal, setShowInfoModal] = useState()
  const [, setErrors] = useContext(ErrorContext)

  const [postData, setPostData] = useState({ url: null, query: null })
  const postState = usePost(postData)

  const genDetailModelUrl = () => {
    const item_id = location.state.id
    return new URL(item_id, modelData.url)
  }

  const sposta = () => {
    const body = {
      ...formValues
    }

    delete body.pelle['Caricata il']

    const url = new URL('move/', modelData.url)
    setPostData({ url, query: body })
  }

  useEffect(() => {
    if (postState.data != null) {
      navigate(location?.state?.from)
    }
  }, [postState.data])

  const handleErrors = (errData, errType) => {
    setErrors(errData ?? {})
  }

  useEffect(() => {
    if (postState.error != null) {
      const errData = postState.error.data
      const errType = postState.error.type
      handleErrors(errData, errType)
    }
  }, [postState.error])

  //
  useEffect(() => {
    return () => {
      setErrors({})
    }
  }, [])

  useEffect(() => {
    const pelle = location.state.pelle
    if (pelle != null) {
      setFormValuesByName('pelle', pelle)
    } else {
      setShowInfoModal(true)
    }
  }, [location.state.pelle?.id])

  return (
    <main>

      <div
        className='flex flex-col justify-center items-center gap-5 h-full pb-5'
      >

        <h2 className='text-xl'>Cambio di magazzino per pelle n° {location.state?.pelle?.id} </h2>

        <CambioMagazzinoForm
          formValues={formValues}
          setFormValuesByName={setFormValuesByName}
          setFormValuesByEvent={setFormValuesByEvent}
        />

        <div
          className='flex justify-around w-full mt-5'
        >

          <Button
            text='Sposta'
            onclick={sposta}
          />
        </div>
      </div>

      {
        showInfoModal === true &&
          <InfoModal
            closeModal={() => navigate('/inventario')}
            title='ERRORE'
            text={INFO_MODAL_TEXT}
            btnText='Vai a Inventario'
          />
      }

    </main>

  )
}

export default CambioMagazzinoFormPage

// <Slider
//
//   header="Dettagli"
//
// >
//
//
//   <PelleDetails
//     data={location.state?.pelle}
//     horizontal={true}
//   />
//
//
// </Slider>
