

import { useRef, useState, useEffect } from 'react'
import "./Checkbox.css"

const CheckBox = ({ label, name, formValues, setValue, reverse }) =>{

	const checkBoxRef = useRef()

	return (
		<label 
			className='check-box-wrap mx-4'
			onClick={ (event) => {
				event.stopPropagation() 

				setValue( 
          reverse ?
          checkBoxRef.current.checked :
          !checkBoxRef.current.checked 
        )
			}}
		>
			<input 
				ref={ checkBoxRef }
        name={name}
				type='checkbox'
				checked={ reverse === true ? !formValues[name] : formValues[name]}
				className='check-box'
				onChange={ () =>  null
				}
				onClick={ (event) => {
					event.stopPropagation() 
				}}
			/>
			<span className='check-mark'>
			</span>
			{ 
			label
			}
		</label>
	)
}



export default CheckBox
