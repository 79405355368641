
import Modal from '../wrap/Modal'
import { useDelete } from '../../Hooks'
import {  useEffect,  useState } from 'react'
import { INVENTARI_URL, PELLE_URL } from '../../Costants'
import Button from '../button/Button'



const DeleteInventarioModal = ({ showHandler, inventario }) => {


  const [delConf, setDelConf ] = useState({url:null})
  const delState = useDelete(delConf)



  useEffect(() => {
    if (delState.data != null ){

      showHandler(false)
    }
  }, [delState?.data])


  return (
    <Modal
      showHadler={showHandler}
    >

      <div
        className='flex flex-col justify-center items-center gap-3 h-full '
      >

        <h2 className='text-xl'> Vuoi eliminare questo inventario? </h2>
        
        <div 
          className='p-3 flex gap-[5vw] justify-center items-center flex-col md:flex-row'
        >

        <Button
          text='Elimina'
          onclick={() => {

            const url = new URL( `${inventario.id}`, INVENTARI_URL )
            setDelConf({url:url})
          }
          }
        />

        <Button
          text='Annulla'
          onclick={() => showHandler(false)}
        />

              </div>

           </div>

    </Modal>
  )
}


export default DeleteInventarioModal
