import { useEffect, useMemo, useState, useTransition } from 'react'
import { ANAGRAFICA_URL } from '../Costants'
import { useGet } from '../Hooks'
import Button from '../views/button/Button'
import AnagraficaFilter from '../views/anagrafica/AnagraficaFilter'
import ImportModal from '../views/anagrafica/ImportModal'
import Table from '../views/table/Table'
import AnagraficaModal from '../views/anagrafica/AnagraficaModal'
import Loader from '../views/loader/Loader'

// import ProdottoModal from '../views/prodotto/ProdottoModal'

const AnagraficheArticoli = () => {
  const [isPending, startTransition] = useTransition()

  const [showImportModal, setShowImportModal] = useState(false)
  const [showAnModal, setShowAnModal] = useState(false)
  const [showPrModal, setShowPrModal] = useState(false)
  const [action, setAction] = useState(null)
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)

  const [getData, setGetData] = useState({ url: null })
  const getState = useGet(getData)

  const filterCallback = (values) => {
    const params = new URLSearchParams()

    for (const key of Object.keys(values)) {
      if (values[key] != null && values[key].toString().trim() !== '') {
        params.append(key, values[key])
      }
    }
    const url = new URL(ANAGRAFICA_URL)
    url.search = params.toString()
    setGetData({ url })
  }

  const rowCallback = (some) => {
    setSelectedItem(some)
    setShowAnModal(true)
  }

  const respCallback = (obj) => {
    if (typeof obj === 'object') {
      if (action === 'create') {
      // Create new
        setItems(old => {
          old.push(obj)
          return [...old]
        })
      } else {
        // Update
        setItems(old => {
          let oldIdx = 0
          const newItems = old.filter(ele => {
            if (ele.id == obj.id) {
              oldIdx = old.indexOf(ele)
              return false
            }
            return true
          })
          newItems.splice(oldIdx, 0, obj)

          return [...newItems]
        })
      }
    } else {
      // Delete
      setItems(old => {
        const newItems = old.filter(ele => ele.id !== obj)
        return [...newItems]
      })
    }

    setAction(null)
    setShowAnModal(false)
  }

  useEffect(() => {
    setGetData({ url: ANAGRAFICA_URL })
    return () => {
      setGetData({ url: null })
    }
  }, [])

  useEffect(() => {
    if (getState.data != null) {
      startTransition(() => {
        setItems(getState.data)
      })
      setGetData({url:null})
    }
  }, [getState.data])

  const renderContent = useMemo(() => {
    if (getState.loading === true || isPending === true) {
      return <Loader />
    } else {
      return (
        <>
          <Table
            items={items}
            rowCallback={rowCallback}
          />

          <div
            className='mt-8 flex gap-8'
          >

            <Button
              text='Importa'
              onclick={() => setShowImportModal(true)}
            />

            <Button
              text='Nuovo'
              onclick={() => {
                setAction('create')
                setShowAnModal(true)
              }}
            />

          </div>
        </>
      )
    }
  }, [items, isPending, getState.loading])

  return (
    <main
      className='flex flex-col items-center justify-center'
    >

      <AnagraficaFilter
        filterCallback={filterCallback}
      />

      {renderContent}

      {
        showImportModal === true &&
          <ImportModal
            showHandler={setShowImportModal}
            successCallback={ () => {
              setGetData({url:ANAGRAFICA_URL})
            }
            }
          />
      }

      {
        showAnModal === true &&
          <AnagraficaModal
            showHandler={(show) => {
              setAction(null)
              setShowAnModal(show)
            }}
            item={selectedItem ?? 0}
            respCallback={respCallback}
            action={action}
            switchModal={() => {
              setShowPrModal(true)
              setShowAnModal(false)
            }}
          />
      }


    </main>
  )
}

      // {
      //   showPrModal === true &&
      //     <ProdottoModal
      //       showHandler={(show) => {
      //         setAction(null)
      //         setShowPrModal(show)
      //       }}
      //       item={ 0}
      //       respCallback={() => setShowPrModal(false)}
      //       action='create'
      //       selectedParent={selectedItem ?? 0 }
      //     />
      // }


export default AnagraficheArticoli
