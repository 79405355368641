import { useEffect, useMemo, useState, useTransition } from "react";
import { CARICO_URL } from "../Costants";
import { useGet } from "../Hooks";
import Button from "../views/button/Button";
import CaricoFilter from "../views/carico/CaricoFilter";
import Table from "../views/table/Table";
import CaricoModal from "../views/carico/CaricoModal";
import Loader from "../views/loader/Loader";
import { useNavigate} from "react-router-dom";
import SpostaCavallettoModal from "../views/carico/SpostaCavallettoModal";

const CaricoPage = () => {
  const navigate = useNavigate();

  const [isPending, startTransition] = useTransition();

  const [showCavallettoModal, setShowCavallettoModal] = useState(false);
  const [showAnModal, setShowAnModal] = useState(false);
  const [action, setAction] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [getData, setGetData] = useState({ url: null });
  const getState = useGet(getData);

  const filterCallback = (values) => {
    const params = new URLSearchParams();
    for (const key of Object.keys(values)) {
      if (values[key] != null && values[key].toString().trim() !== "") {
        params.append(key, values[key]);
      }
    }
    const url = new URL(CARICO_URL);
    url.search = params.toString();
    setGetData({ url });
  };

  const rowCallback = (some) => {
    navigate("/carico/edit", {
      state: {
        id: some,
      },
    });
  };

  const respCallback = (obj) => {
    if (typeof obj === "object") {
      if (action === "create") {
        // Create new
        setItems((old) => {
          old.push(obj);
          return [...old];
        });
      } else {
        // Update
        setItems((old) => {
          let oldIdx = 0;
          const newItems = old.filter((ele) => {
            if (ele.id == obj.id) {
              oldIdx = old.indexOf(ele);
              return false;
            }
            return true;
          });
          newItems.splice(oldIdx, 0, obj);

          return [...newItems];
        });
      }
    } else {
      // Delete
      setItems((old) => {
        const newItems = old.filter((ele) => ele.id !== obj);
        return [...newItems];
      });
    }

    setAction(null);
    setShowAnModal(false);
  };

  useEffect(() => {
    setGetData({ url: CARICO_URL });
    return () => {
      setGetData({ url: null });
    };
  }, []);

  useEffect(() => {
    if (getState.data != null) {
      startTransition(() => {
        setItems(getState.data);
      });
    }
  }, [getState.data]);

  const renderContent = useMemo(() => {
    if (getState.loading === true || isPending === true) {
      return <Loader />;
    } else {
      return (
        <>
          <Table items={items} rowCallback={rowCallback} />

          <div className="mt-8 flex gap-8">
            <Button
              text="Nuovo"
              onclick={() => {
                navigate("/carico/add");
              }}
            />
            <Button
              text="Sposta carichi per cavalletto"
              onclick={() => setShowCavallettoModal(true)}
            />
          </div>
        </>
      );
    }
  }, [items, isPending, getState.loading]);

  return (
    <main className="flex flex-col items-center justify-center">
      <CaricoFilter filterCallback={filterCallback} />

      {renderContent}

      {showAnModal === true && (
        <CaricoModal
          showHandler={(show) => {
            setAction(null);
            setShowAnModal(show);
          }}
          item={selectedItem ?? 0}
          respCallback={respCallback}
          action={action}
        />
      )}

      {showCavallettoModal === true && (
        <SpostaCavallettoModal
          showHandler={(show) => {
            setShowCavallettoModal(show);
          }}
        />
      )}
    </main>
  );
};

export default CaricoPage;
