
import { BiSolidDownload } from "react-icons/bi";
import { INVENTARI_URL } from "../../Costants";




const dowloadFile = async (url) => {
    const request = new Request(url, {
      method: 'GET',
      credentials: 'include'
    })
    const response = await fetch(request)
    const contentDisposition = response.headers.get('content-type')
    const blob = await response.blob()
    const matches = contentDisposition && contentDisposition.match(/filename="(.*)"/)
    const fileName = matches && matches[1] ? matches[1] : 'download.xlsx'
    const blobUrl = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = blobUrl
    anchor.download = fileName
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
    window.URL.revokeObjectURL(blobUrl)

}

const InventarioRow = ({inventario, selectCallback}) => {

  const {nome, data } = inventario

  const clickDownload = () => {
    const url = new URL(`${inventario.id}`, INVENTARI_URL)
    dowloadFile(url)
  }


  return (
    <li
      className="flex items-center gap-12 justify-start p-3"
    >
      <div
        onClick={ () => selectCallback(inventario)
        }
    >
        <p>{nome}</p>
        <p
          className="text-sm"
        >{data}</p>
      </div>

        <i
          className="cursor-pointer text-2xl"
          onClick={ clickDownload }
        >
          <BiSolidDownload />
        </i>

    </li>
  )
}


export default InventarioRow
