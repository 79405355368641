import TextInput from '../input/TextInput'
import Select from '../input/Select'
import ModalBtnGroup from '../misc/ModalBtnGroup'
import Modal from '../wrap/Modal'
import { useDelete, useGet, useModelData, usePost, usePut } from '../../Hooks'
import { useContext, useEffect, useLayoutEffect, useReducer, useState } from 'react'
import InventarioForm from './InventarioForm'
import { ErrorContext } from '../../context/ErrorContex'
import { PELLE_URL } from '../../Costants'
import PelleDetails from './PelleDetails'
import Button from '../button/Button'
import { useNavigate } from 'react-router-dom'

const requestReducer = (old, action) => {
  switch (action.key) {
    case 'SET_UP':
      return {
        ...old,
        tmp_url: action.url
      }
    case 'UPDATE':
      return {
        ...old,
        update_url: action.url,
        body: action.body
      }
    case 'DELETE':
      return {
        ...old,
        delete_url: action.url
      }

    case 'GET':
      return {
        ...old,
        get_url: action.url
      }

    case 'CREATE':
      return {
        ...old,
        create_url: old.tmp_url,
        body: action.body
      }

    default:
      return old
  }
}

const initailReducerValue = {
  tmp_url: null,
  update_url: null,
  delete_url: null,
  create_url: null,
  get_url: null,
  body: null
}

const InventarioModal = ({ item, showHandler, respCallback, selectedParent = null }) => {
  const modelData = useModelData('pelle')

  const navigate = useNavigate()

  const [reqState, dispatch] = useReducer(requestReducer, initailReducerValue)

  const [, setErrors] = useContext(ErrorContext)

  const [values, setValues] = useState({})

  const getState = useGet({ url: reqState.get_url })

  const create = () => {
    dispatch({ key: 'CREATE', body: { ...values } })
  }

  const update = () => {
    const url = new URL(item, modelData.url)
    dispatch({ key: 'UPDATE', body: { ...values }, url })
  }
  const del = () => {
    dispatch({ key: 'DELETE', url: new URL(item, reqState.tmp_url) })
  }

  useEffect(() => {
    if (getState.data != null) {
      dispatch({ key: 'SET_UP', url: modelData.url })
      setValues(getState.data)
    }
  }, [getState.data])

  useEffect(() => {
    if (getState.error != null) {
      setValues(getState.error)
    }
  }, [getState.error])

  useEffect(() => {
    if (modelData.type == null) return
    const url = new URL(item, PELLE_URL)
    dispatch({ key: 'GET', url })
  }, [modelData.type, item])

  useEffect(() => {
    return () => {
      setErrors({})
    }
  }, [])

  return (
    <Modal
      showHadler={showHandler}
    >

      <div
        className='flex flex-col justify-center items-center gap-3 h-full '
      >

        <h2 className='text-xl'> Dettagli pelle  </h2>
        <PelleDetails
          data={values}
        />

        <div 
          className='p-3 flex gap-[5vw] justify-center items-center flex-col md:flex-row'
        >
        <Button
          text='Vai al carico'
          onclick={() => navigate('/carico/edit', {
            state: { id: values.carico_id }
          })}
        />

        <Button
          text='Lavorazione / venduto'
          onclick={() => navigate('/movimento/add', {
            state: { 
                pelle:values,
                from:"/inventario"
              }
          })}
        />
        
        <Button
          text='Cambia magazzino'
          onclick={() => navigate('/cambio_magazzino', {
            state: { 
                pelle:values,
                from:"/inventario"
              }
          })}
        />

        </div>

        <div
          className='flex justify-around w-full'
        />
      </div>

    </Modal>
  )
}

// {
//   error != null &&
//     <ErrorLabel error={error}/>
// }

export default InventarioModal
