import { useEffect, useMemo, useState, useTransition } from 'react'
import { GIACENZE_URL } from '../Costants'
import { useGet, usePost } from '../Hooks'
import Button from '../views/button/Button'
import GiacenzeFilter from '../views/giacenze/GiacenzeFilter'
import Table from '../views/table/Table'
// import GiacenzeModal from '../views/giacenze/GiacenzeModal'
import Loader from '../views/loader/Loader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import GiacenzeGroupBy from '../views/giacenze/GiacenzeGroupBy'

const GiacenzePage = () => {
  const navigate = useNavigate()

  const [isPending, startTransition] = useTransition()

  const [showAnModal, setShowAnModal] = useState(false)
  const [action, setAction] = useState(null)
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)

  const [postData, setPostData] = useState({ url: null, query: null })
  const postState = usePost(postData)

  const filterCallback = (action, values) => {
    setPostData(old => {
      return {
        url: GIACENZE_URL,
        query: {
          ...(old.query || {}),
          [action]: {
            ...(old.query && old.query[action]),
            ...values
          }
        }
      }
    })
  }

  const rowCallback = (some) => {
    setSelectedItem(some)
    setShowAnModal(true)
  }

  const respCallback = (obj) => {
    if (typeof obj === 'object') {
      if (action === 'create') {
      // Create new
        setItems(old => {
          old.push(obj)
          return [...old]
        })
      } else {
        // Update
        setItems(old => {
          let oldIdx = 0
          const newItems = old.filter(ele => {
            if (ele.id == obj.id) {
              oldIdx = old.indexOf(ele)
              return false
            }
            return true
          })
          newItems.splice(oldIdx, 0, obj)

          return [...newItems]
        })
      }
    } else {
      // Delete
      setItems(old => {
        const newItems = old.filter(ele => ele.id !== obj)
        return [...newItems]
      })
    }

    setAction(null)
    setShowAnModal(false)
  }

  useEffect(() => {
    setPostData({ url: GIACENZE_URL, query: {} })
    return () => {
      setPostData({ url: null })
    }
  }, [])

  useEffect(() => {
    if (postState.data != null) {
      startTransition(() => {
        let content = postState.data

        const keys = Object.keys(postState.data[0] ?? {})

        for (const key of keys) {
          if (content.every((row) => {
            return row[key] == null
          })) {
            content = content.map(item => {
              delete item[key]
              return item
            })
          }
        }
        setItems(content)
      })
    }
  }, [postState.data])

  const renderContent = useMemo(() => {
    if (postState.loading === true || isPending === true) {
      return <Loader />
    } else {
      return (
        <>
          <Table
            items={items}
            rowCallback={rowCallback}
          />

        </>
      )
    }
  }, [items, isPending, postState.loading])

  return (
    <main
      className='flex flex-col items-center justify-center'
    >

      <GiacenzeFilter
        filterCallback={(values) => filterCallback('filter', values)}
      />

      <GiacenzeGroupBy
        filterCallback={(values) => filterCallback('group', values)}
      />

      {
        renderContent
      }

    </main>
  )
}

// {
//   showAnModal === true &&
//     <GiacenzeModal
//       showHandler={(show) => {
//         setAction(null)
//         setShowAnModal(show)
//       }}
//       item={selectedItem ?? 0}
//       respCallback={respCallback}
//       action={action}
//     />
// }

export default GiacenzePage
