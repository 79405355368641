import { useEffect, useRef, useState } from 'react'
import { IMPORT_URL } from '../../Costants'
import { usePost } from '../../Hooks'
import Button from '../button/Button'
import Loader from '../loader/Loader'
import Modal from '../wrap/Modal'
import KeysError from './KeysError'

const ImportModal = ({
  showHandler,
  successCallback
}) => {
  const [postData, setPostData] = useState({ url: null, query: null })

  const postState = usePost(postData)

  const formRef = useRef()

  const upload = () => {
    const formData = new FormData(formRef.current)
    setPostData({ url: IMPORT_URL, query: formData, json: false })
  }

  const [keysErrors, setKeysErrors] = useState({})

  useEffect(() => {
    if (postState.error?.type === 'missing keys') {
      setKeysErrors(postState.error.data)
    }
  }, [postState?.error?.type])

  useEffect(() => {
    if (postState.data != null ) {
      successCallback()
      showHandler(false)
    }
  }, [postState?.data ])


  return (

    <Modal
      showHadler={showHandler}
    >

      <div
        className='flex flex-col justify-cente items-center gap-16 overflow-y-auto max-h-[100%] '
      >

        <h1>Importa da Navision</h1>

        {
          Object.keys(keysErrors).length > 0
            ? <KeysError keysErrors={keysErrors} />

            : <>

              <form ref={formRef}>

                <input type='file' name='file' />

              </form>

              {
          postState.loading === true
            ? <Loader />
            : <Button text='Importa' onclick={upload} />
        }

            </>

        }

      </div>

    </Modal>
  )
}

export default ImportModal
