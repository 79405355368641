import { useContext, useMemo, useState } from 'react'
import { ErrorContext } from '../../context/ErrorContex'
import { FaRegCalendarAlt } from "react-icons/fa";

const DateInput = ({
  setValue,
  formValues,
  name,
  placeholder,
  label=null,
  propErrors={},
  disabled=false,
}) => {
  const [errors] = useContext(ErrorContext)


  const renderErrors = useMemo(() => {

    const err = propErrors[name] ?? errors[name]
    return err?.map((errMsg, idx) => {
      return (
        <label 
          key={`text_err_${idx}`}
          className='mb-[3px] block text-sm text-red-500 text-start '>
          {errMsg}
        </label>

      )
    })
  }, [ errors[name] , propErrors  ])

  return (

    <div className={ `relative w-full px-4 min-w-[200px] sm:max-w-[40%] md:max-w-[30%] lg:max-w-[300px] `}>
      <label className='mb-[3px] block text-sm text-blue-800 text-start '>
        { label != null ? label : placeholder}
      </label>

      <div className="relative">

        <input
          type='date'
          placeholder={placeholder}
          name={name}
          className='w-full bg-text-bg rounded-md border border-stroke
                  border-blue-800
                  dark:border-dark-3 py-1 px-2 text-dark-6
                  outline-none transition active:border-primary
                  disabled:cursor-default disabled:bg-gray-2
                  focus:border-amber-500'

          onChange={(event) => {
            setValue(event, true )
            // const val = event.target.value
            // if ( isNaN(val) || val === '' ){
            //   setValue(val)
            // } else {
            //   setValue(Number(val))
            // }
          }}
          value={formValues[name]}
          autoComplete="false"
          disabled={disabled}
        >


      </input>

    
      </div>


      {renderErrors}

    </div>

  )
}

export default DateInput


        // <i
        //         className='absolute right-[25px] bottom-[9px] '
        //       >
        //       <FaRegCalendarAlt/>
        //     </i>
        //
