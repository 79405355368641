import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useForm } from '../../Hooks'
import Select from '../input/Select'
import TextInput from '../input/TextInput'
import AnagraficaModal from './AnagraficaModal'

const AnagraficaModalForm = ({ values, setValues }) => {
  const [
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormValuesByName
  ] = useForm({
    id: '',
    cod_prodotto: '',
    descrizione: '',
    famiglia_id: '',
    famiglia_prodotto_id: '',
    um_id: ''
  })

  const initialRef = useRef(true)

  const [semaphore, setSemaphore] = useState({
    famiglia_id: false,
    famiglia_prodotto_id: false,
    um_id: false
  })

  useLayoutEffect(() => {
    if (
      Object.values(semaphore).every(bool => bool) &&
        initialRef.current === true &&
        Object.keys(values).length !== 0) {
      initialRef.current = false
      setFormValues(values)
    }
  }, [values, semaphore])

  useEffect(() => {
    if (Object.values(semaphore).every(bool => bool)) {
      setValues(formValues)
    }
  }, [formValues])

  useEffect(() => {
    return () => {
      initialRef.current = true
    }
  }, [])

  return (

    <form className='flex flex-wrap gap-3 overflow-y-auto h-full'>

      <TextInput
        name='cod_prodotto'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        placeholder='Cod. prodotto'
        uppercase={true}
      />

      <TextInput
        name='descrizione'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        placeholder='Descrizione'
      />

      <Select
        name='famiglia_id'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        setSemaphore={setSemaphore}
      />
      <Select
        name='famiglia_prodotto_id'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        setSemaphore={setSemaphore}
      />
      <Select
        name='um_id'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        setSemaphore={setSemaphore}
      />

    </form>
  )
}

export default AnagraficaModalForm
