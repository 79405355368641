import { useEffect, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from '../../Hooks'
import DigitInput from '../input/DigitInput'
import Select from '../input/Select'
import TextInput from '../input/TextInput'

import { FaTimes } from 'react-icons/fa'
import TextArea from '../input/TextArea'

const PelleForm = ({
  disabled,
  idx,
  um,
  formValues,
  setFormValues,
  setFormValuesByEvent,
  errors,
  removeSelf,
  initialValues
}) => {
  //
  // const [
  //   formValues,
  //   setFormValues,
  //   setFormValuesByEvent,
  //   setFormValuesByName
  // ] = useForm({
  //
  //   dimensione: '',
  //   scelta_id: '',
  //   id_tag: ''
  // })
  //
  const params = useParams()

  //
  //
  // useEffect(() => {
  //   setValue(formValues)
  // }, [formValues])
  //
  // useEffect(() => {
  //   if (params?.action === 'add') {
  //     setValue(formValues)
  //   } else if (initialValues != null) {
  //     setFormValues(initialValues)
  //   }
  //   return () => {
  //   //  removeSelf()
  //   }
  // }, [initialValues])
  //
  return (

    <div
      className=' w-full pb-4 px-4 border-b border-b-black'
    >

      <div
        className='flex items-center mt-2 justify-center xl:justify-start xl:pl-14'
      >

        <h3
          className='mr:3 lg:mr-9 whitespace-nowrap'
        >{`Pelle ${idx + 1}`}
        </h3>

        <form
          className='flex flex-wrap md:flex-nowrap gap-3 overflow-y-auto h-full  justify-center'
        >

          <DigitInput
            name='dimensione'
          // name={`dimensione_tmp${idx}`}
            formValues={formValues}
            setValue={setFormValuesByEvent}
            placeholder='Dimensione'
            propErrors={errors}
            disabled={disabled}
          />

          <Select
            name='scelta_id'
          // name={`scelta_id_tmp${idx}`}
            formValues={formValues}
            setValue={setFormValuesByEvent}
            propErrors={errors}
            disabled={disabled}
          />

          <TextInput
            name='id_tag'
          // name={`id_tag_tmp${idx}`}
            formValues={formValues}
            setValue={setFormValuesByEvent}
            placeholder='ID Tag'
            large
            propErrors={errors}
            disabled={disabled}
          />

          <TextArea
            label='Note'
            name='note'
            formValues={formValues}
            setValue={setFormValuesByEvent}
            placeholder='Note per questa pelle'
            propErrors={errors}
            disabled={disabled}
          />

        </form>
        {
           params?.action === 'edit' &&
           disabled === false &&
             <i
               className='self-center mt-[15px] text-xl '
               onClick={removeSelf}
             >
               <FaTimes />
             </i>
        }

      </div>
    </div>
  )
}

export default PelleForm
