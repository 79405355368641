import { useState, useRef, useReducer, useEffect, useCallback, useLayoutEffect } from 'react'
import useSessionStorageState from 'use-session-storage-state'
import { useLocation, useNavigate } from 'react-router-dom'
import { PELLE_URL, REFRESH_URL, BASE_WS, LOGOUT_URL, FAMIGLA_PRODOTTO_URL, FAMIGLA_URL, UM_URL, BASE_URL, SCELTA_URL, TRATTAMENTO_URL, MAGAZZINO_URL, ANAGRAFICA_URL, CARICO_URL, CAUSALE_MOVIMENTO_URL, MOVIMENTI_URL } from './Costants'

export const getValueFromItem = (obj) => {
  const valKey = Object.keys(obj).filter(key => key !== 'id')[0]
  return obj[valKey]
}

export const useModelData = (name) => {
  const [data, setData] = useState({})

  useEffect(() => {
    let type = name.replaceAll('_id', '')
    type = type.replaceAll('_cod_pr', '')
    switch (type) {
      case 'famiglia_prodotto':
        setData({
          type,
          header: 'Famiglie prodotto',
          single_header: 'Famiglia prodotto',
          label: 'famiglia prodotto',
          url: FAMIGLA_PRODOTTO_URL,
          name: 'famiglia_prodotto_id'

        })
        break

      case 'famiglia':
        setData({
          type,
          header: 'Famiglie',
          single_header: 'Famiglia',
          label: 'famiglia',
          url: FAMIGLA_URL,
          name: 'famiglia_id'
        })
        break

      case 'um':
        setData({
          type,
          header: 'Unità di misura',
          single_header: 'Unità di misura',
          label: 'unità di misura',
          url: UM_URL,
          name: 'um_id'
        })
        break

      case 'scelta':
        setData({
          type,
          header: 'Scelte',
          single_header: 'Scelta',
          label: 'scelta',
          url: SCELTA_URL,
          name: 'scelta_id'
        })
        break

      case 'trattamento':
        setData({
          type,
          header: 'Trattamenti',
          single_header: 'Trattamento',
          label: 'trattamento',
          url: TRATTAMENTO_URL,
          name: 'trattamento_id'
        })
        break

      case 'magazzino':
        setData({
          type,
          header: 'Codici magazzini',
          single_header: 'Codice magazzino',
          label: 'codice magazzino',
          url: MAGAZZINO_URL,
          name: 'magazzino_id'
        })
        break
      case 'anagrafica':
        setData({
          type,
          header: 'Anagrafiche',
          single_header: 'Anagrafica',
          label: 'anagrafica',
          url: ANAGRAFICA_URL,
          name: 'anagrafica'
        })
        break

      case 'carico':
        setData({
          type,
          header: 'Carichi',
          single_header: 'Carico',
          label: 'carico',
          url: CARICO_URL,
          name: 'carico'
        })
        break
      case 'pelle':
        setData({
          type,
          header: 'Pelli',
          single_header: 'pelle',
          label: 'pelle',
          url: PELLE_URL,
          name: 'pelle'
        })
        break

      case 'causale':
        setData({
          type,
          header: 'Causali movimenti',
          single_header: 'Causale movimento',
          label: 'causale movimento',
          url: CAUSALE_MOVIMENTO_URL,
          name: 'causale_movimento'
        })
        break

      case 'causale_movimento':
        setData({
          type,
          header: 'Causali movimenti',
          single_header: 'Causale movimento',
          label: 'causale movimento',
          url: CAUSALE_MOVIMENTO_URL,
          name: 'causale_movimento'
        })
        break

      case 'movimento_magazzino':
        setData({
          type,
          header: 'Movimenti magazzino',
          single_header: 'Movimento magazzino',
          label: 'movimento magazzino',
          url: MOVIMENTI_URL,
          name: 'movimento_magazzino'
        })
        break

      default:
        break
    }
  }, [name, setData])

  return data
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}

export const useWebSocket = () => {
  const [received, setReceived] = useState(null)
  const [wsState, setWsState] = useState(0)
  const wsRef = useRef()

  const genWs = () => {
    const ws = new WebSocket(BASE_WS)
    ws.onmessage = msg => {
      if (msg.data != null) {
        const data = JSON.parse(msg.data)
        setReceived(data)
      }
    }
    ws.onopen = () => {
      setWsState(ws.readyState)
    }
    ws.onclose = () => {
      setWsState(ws.readyState)
    }

    wsRef.current = ws
  }

  useEffect(() => {
    if (
      wsState === WebSocket.CLOSING ||
			wsState === WebSocket.CLOSED
    ) {
      refreshToken({}, true)
        .then(_ => {
          genWs()
        })
    }
  }, [wsState])

  useEffect(() => {
    genWs()

    return () => {
      if (wsRef?.current != null) {
        wsRef.current.onclose = () => { }
        wsRef.current.close()
      }
      // clearInterval(interval)
    }
  }, [])

  return [received, setReceived]
}

export const useAuthCheck = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [user] = useSessionStorageState('user', { defaultValue: null })

  useEffect(() => {
    if (user == null || user === {}) {
      navigate('/login')
    }
  }, [user, navigate])
}

const genHeaders = (json) => {
  const baseHeaders = {
    Accept: 'application/json'
  }

  if (json === true) {
    return new Headers({ ...baseHeaders, 'Content-Type': 'application/json' })
  } else {
    return new Headers(baseHeaders)
  }
}

const refreshToken = async (original_request, empty = false) => {
  const controller = new AbortController()
  const headers = genHeaders(false)

  const request = new Request(REFRESH_URL, {
    method: 'GET',
    signal: controller.signal,
    headers,
    credentials: 'include'
  })
  return await fetch(request)
    .then(resp => {
      if (resp.ok) {
        if (empty === false) {
          return fetch(original_request)
            .then(origina_resp => {
              return origina_resp
            })
        } else {}
      } else {
        return resp
      }
    })
    .catch(err => {
      console.log('error in fetch', err)
      return err
    })
}

export const useLogout = () => {
  const navigate = useNavigate()
  const [, setUser] = useSessionStorageState('user')

  const doLogout = useCallback(() => {
    const headers = genHeaders()

    const request = new Request(LOGOUT_URL,
      {
        headers,
        method: 'GET',
        credentials: 'include'
      })

    fetch(request)
      .then(() => {
        setUser({})
        navigate('/login')
      })
      .catch(() => {
        setUser({})
        navigate('/login')
      })
  }, [setUser])

  return doLogout
}

const handleErrString = (msg) => {
  // Failed to deserialize the JSON body into the target type: n_pelli: expected a int at line 1 column 14

  const [, name, exp] = msg.split(':')

  let res = ''

  if (exp.includes('int')) {
    res = 'deve essere un numero intero'
  }

  return {
    [name.trim()]: [res]
  }
}

const useRespHandler = () => {
  const doLogout = useLogout()
  const respHandler = useCallback(async (resp, dispatch, request) => {
    const resp_clone = resp.clone()

    if (resp.ok === true) {
      resp.json()
        .then(data => {
          dispatch({ type: 'DATA', data })
        })
        .catch(err => {
          dispatch({ type: 'ERROR', error: err })
        })
    } else {
      resp.json()
        .then(async err => {
          if (err?.type?.includes('token error')) {
            await refreshToken(request)
              .then(refresh_resp => {
                if (refresh_resp.ok) {
                  refresh_resp.json()
                    .then(data => {
                      dispatch({ type: 'DATA', data })
                    })
                    .catch(err => {
                      dispatch({ type: 'ERROR', error: err })
                    })
                } else {
                  refresh_resp.json()
                    .then(err => {
                      dispatch({ type: 'ERROR', error: err })
                      doLogout()
                    })
                    .catch(err => {
                      dispatch({ type: 'ERROR', error: err })
                      doLogout()
                    })
                }
              })
              .catch(err => {
                dispatch({ type: 'ERROR', error: err })
                doLogout()
              })
          } else {
            dispatch({ type: 'ERROR', error: err })
          }
        })
        .catch(err => {
          const txt = resp_clone.text()
            .then(t => {

              try{

              const err_data = handleErrString(t)

              const new_err = {
                type: 'error type',
                data: err_data
              }

              dispatch({ type: 'ERROR', error: new_err })
            } catch{

              dispatch({ type: 'ERROR', error:err })
            }
            })
        })
    }
  }, [doLogout])

  return respHandler
}

const fetchReducer = (oldState, action) => {
  switch (action.type) {
    case 'DATA':
      return {
        data: action.data,
        loading: false,
        error: null
      }
    case 'LOADING':
      return {
        data: null,
        loading: true,
        error: null
      }
    case 'STOP_LOADING':
      return {
        ...oldState,
        loading: false
      }
    case 'ERROR':
      return {
        data: null,
        loading: null,
        error: action.error
      }
    default:
      break
  }
}

export const useDelete = ({ url }) => {
  const [state, dispatch] = useReducer(fetchReducer, { data: null, loading: false, error: null })
  const controller = useRef()
  const startedRef = useRef(false)
  const urlRef = useRef('')

  const respHandler = useRespHandler()

  useEffect(() => {
    if (
      url != null &&
                        startedRef.current === false &&
                        (
                          urlRef.current !== url.href
                        )
    ) {
      controller.current != null && controller.current.abort()
      controller.current = new AbortController()
      urlRef.current = url.href
      startedRef.current = true
      dispatch({ type: 'LOADING' })

      const headers = genHeaders(false)

      const request = new Request(url, {
        method: 'DELETE',
        signal: controller.current.signal,
        headers,
        credentials: 'include'
      })
      const origin_request = request.clone()
      fetch(request)
        .then(resp => {
          respHandler(resp, dispatch, origin_request)
        })
        .catch(err => {
          console.log('error in fetch', err)
          dispatch({ type: 'ERROR', error: err })
        })
    } else {
      urlRef.current = ''
    }
  }, [
    url,
    respHandler
  ])

  useEffect(() => {
    if (state?.data != null || state?.error != null) {
      dispatch({ type: 'STOP_LOADING' })
      startedRef.current = false
    }
  }, [
    state?.data,
    state?.error
  ])

  useEffect(() => {
    return () => {
      controller.current &&
       controller.current.abort()
    }
  }, [])

  return state
}

export const useGet = ({ url }) => {
  const [state, dispatch] = useReducer(fetchReducer, { data: null, loading: false, error: null })
  const controller = useRef()
  const startedRef = useRef(false)
  const urlRef = useRef('')

  const respHandler = useRespHandler()

  useEffect(() => {
    if (
      url != null &&
      startedRef.current === false &&
      (
        urlRef.current !== url.href
      )
    ) {
      controller.current != null && controller.current.abort()
      controller.current = new AbortController()
      urlRef.current = url.href
      startedRef.current = true
      dispatch({ type: 'LOADING' })

      const headers = genHeaders(false)

      const request = new Request(url, {
        method: 'GET',
        signal: controller.current.signal,
        headers,
        credentials: 'include'
      })
      const origin_request = request.clone()
      fetch(request)
        .then(resp => {
          respHandler(resp, dispatch, origin_request)
        })
        .catch(err => {
          console.log('error in fetch', err)
          dispatch({ type: 'ERROR', error: err })
        })
    } else {
      urlRef.current = ''
    }
  }, [
    url,
    respHandler
  ])

  useEffect(() => {
    if (state?.data != null || state?.error != null) {
      dispatch({ type: 'STOP_LOADING' })
      startedRef.current = false
    }
  }, [
    state?.data,
    state?.error
  ])

  useEffect(() => {
    return () => {
      controller.current &&
                                controller.current.abort()
    }
  }, [])

  return state
}

export const usePut = ({ url, query, json = true }) => {
  const [state, dispatch] = useReducer(fetchReducer, { data: null, loading: false, error: null })
  const controller = useRef()
  const startedRef = useRef(false)
  const urlRef = useRef('')
  const queryRef = useRef('')
  const respHandler = useRespHandler()

  useEffect(() => {
    if (
      url != null &&
      query != null &&
      startedRef.current === false &&
      (
        urlRef.current !== url.href ||
              queryRef.current !== JSON.stringify(query)
      )
    ) {
      controller.current != null && controller.current.abort()
      controller.current = new AbortController()
      urlRef.current = url.href
      startedRef.current = true
      dispatch({ type: 'LOADING' })


      const body = remove_blank({ ...query })

      if (json === true) { queryRef.current = JSON.stringify(body) } else { queryRef.current = query }

      const headers = genHeaders(json)

      const request = new Request(url, {
        method: 'PUT',
        body: queryRef.current,
        signal: controller.current.signal,
        headers,
        credentials: 'include'
      })
      const origin_request = request.clone()
      fetch(request)
        .then(resp => {
          respHandler(resp, dispatch, origin_request)
        })
        .catch(err => {
          console.log('error in fetch', err)
          dispatch({ type: 'ERROR', error: err })
        })
    } else {
      urlRef.current = ''
      queryRef.current = ''
    }
  }, [
    json,
    url,
    query,
    respHandler
  ])

  useEffect(() => {
    if (state?.data != null || state?.error != null) {
      dispatch({ type: 'STOP_LOADING' })
      startedRef.current = false
    }
  }, [
    state?.data,
    state?.error
  ])

  useEffect(() => {
    return () => {
      controller.current &&
                                controller.current.abort()
    }
  }, [])

  return state
}

const remove_blank = (obj) => {
  for (var prop in obj) {
    if (obj[prop] != null && obj[prop].toString().trim() === '') {
      delete obj[prop];
    } else if (typeof obj[prop] === 'object') {
      remove_blank(obj[prop]);
      // if (Object.keys(obj[prop]).length === 0) {
      //   delete obj[prop];
      // }
    }
  }

  return obj

  // for (const [key, val] of Object.entries(dict)) {
  //   if (val != null && val.toString().trim() === '') {
  //     delete dict[key]
  //   }
  // }
  // return dict
}

export const usePost = ({ url, query, json = true }) => {
  const [state, dispatch] = useReducer(fetchReducer, { data: null, loading: false, error: null })
  const controller = useRef()
  const startedRef = useRef(false)
  const urlRef = useRef('')
  const queryRef = useRef('')
  const respHandler = useRespHandler()

  useEffect(() => {
    if (
      url != null &&
      query != null &&
      startedRef.current === false &&
      (
        urlRef.current !== url.href ||
              queryRef.current !== JSON.stringify(query)
      )
    ) {
      controller.current != null && controller.current.abort()
      controller.current = new AbortController()
      urlRef.current = url.href
      startedRef.current = true
      dispatch({ type: 'LOADING' })

      const body = remove_blank({ ...query })

      if (json === true) { queryRef.current = JSON.stringify(body) } else { queryRef.current = query }

      const headers = genHeaders(json)

      const request = new Request(url, {
        method: 'POST',
        body: queryRef.current,
        signal: controller.current.signal,
        headers,
        credentials: 'include'
      })
      const origin_request = request.clone()
      fetch(request)
        .then(resp => {
          respHandler(resp, dispatch, origin_request)
        })
        .catch(err => {
          console.log('error in fetch', err)
          dispatch({ type: 'ERROR', error: err })
        })
    } else {
      urlRef.current = ''
      queryRef.current = ''
    }
  }, [
    json,
    url,
    query,
    respHandler
  ])

  useEffect(() => {
    if (state?.data != null || state?.error != null) {
      dispatch({ type: 'STOP_LOADING' })
      startedRef.current = false
    }
  }, [
    state?.data,
    state?.error
  ])

  useEffect(() => {
    return () => {
      controller.current &&
                                controller.current.abort()
    }
  }, [])

  return state
}

export const useForm = (initialFormData) => {
  const [formValues, setFormValues] = useState(initialFormData)

  const setFormValuesByEvent = useCallback((event, forceString = false) => {
    let value = event.target.value
    const name = event.target.name
    if (
      !isNaN(value) &&
        value != null &&
        value !== '' &&
        forceString === false
    ) {
      value = Number(value)
    }
    setFormValues(old => {
      return {
        ...old,
        [name]: value
      }
    })
  }, [])

  const setFormValuesByName = useCallback((name, value) => {
    setFormValues(old => {
      return {
        ...old,
        [name]: value
      }
    })
  }, [])

  return [
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormValuesByName
  ]
}

export const useDebounce = (value, delay) => {
  const [debValue, setDebValue] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebValue(value)
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [value, delay])

  return debValue // ?? ''
}
