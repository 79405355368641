import { useModelData } from '../Hooks'
import BaseSettings from '../views/settings/BaseSettings'




const SettingsPage = () => {


  let famigliaData = useModelData("famiglia")
  let famigliaProdottoData = useModelData("famiglia_prodotto")
  let umData = useModelData("um")
  let sceltaData = useModelData("scelta")
  let trattamentoData = useModelData("trattamento")
  let magazzinoData = useModelData("magazzino")
  let causaleData = useModelData("causale_movimento")

      // <BaseSettings modelData={causaleData} />


  return (
    <main
      className='flex flex-col gap-4'
    >

      <BaseSettings modelData={umData}/>
      <BaseSettings modelData={sceltaData}/>
      <BaseSettings modelData={magazzinoData}/>
      <BaseSettings modelData={trattamentoData}/>
      <BaseSettings modelData={famigliaData} />
      <BaseSettings modelData={famigliaProdottoData} />

    </main>
  )
}

export default SettingsPage
