import { useMemo, useState } from 'react'
import TableRow from './TableRow'
import './Table.css'

const Table = ({ items, rowCallback, type }) => {
  const [headers, setHeaders] = useState({})

  const tableContent = useMemo(() => {
    if (Object.keys(headers).length === 0 && items.length !== 0) {
      setHeaders(items[0])
    }
    return (items ?? []).map((item, idx) => {
      return (
        <TableRow
          type={type}
          key={`${type}_row_${idx}`}
          row={item}
          rowCallback={rowCallback}
          isDark={idx % 2 === 0}
        />
      )
    })
  }, [items, type])

  if (items == null || items.length === 0) {
    return null
  } else {
    return (
      <table
        className='w-fit max-h-[50vh] max-w-[90vw] overflow-x-auto grid content-start overflow-y-auto relative'
      >
        <thead
          className='sticky top-0 h-fit font-bold '
        >
          <TableRow
            row={headers}
            header
          />
        </thead>
        <tbody
          className=' inline-block '
        >
          {tableContent}
        </tbody>
      </table>
    )
  }
}

export default Table
