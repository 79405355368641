import { useContext, useEffect, useMemo } from 'react'
import { ErrorContext } from '../../context/ErrorContex'

const DigitInput = ({
  setValue,
  formValues,
  name,
  placeholder,
  max = null,
  min = null,
  propErrors={},
  disabled=false,
}) => {
  const [errors] = useContext(ErrorContext)


  const renderErrors = useMemo(() => {
    const err = propErrors[name] ?? errors[name]
    return err?.map((errMsg, idx) => {
      return (
        <label 
          key={`digit_err_${idx}`}
          className='mb-[3px] block text-sm text-red-500 text-start '>
          {errMsg}
        </label>

      )
    })
  }, [ errors[name] , propErrors  ])




  return (

    <div className='w-full px-4 min-w-[200px] sm:max-w-[40%]  md:max-w-[30%]  lg:max-w-[300px]'>
      <label className='mb-[3px] block text-sm text-blue-800 text-start '>
        {placeholder}
      </label>
      <input
        type='number'
        max={max}

        disabled={disabled} 
        placeholder={placeholder}
        name={name}
        className='w-full bg-text-bg rounded-md border border-stroke
                border-blue-800
                  dark:border-dark-3 py-1 px-2 text-dark-6
                  outline-none transition active:border-primary
                  disabled:cursor-default disabled:bg-gray-2
                  focus:border-amber-500'

        onInput={(event) => {
          const val = event.target.value
          if (val.trim() === '') {
            if (formValues[name].toString().length === 1) {
              event.target.value = ''
            } else {
              event.target.value = formValues[name]
            }
          }

          if (max != null && val > max) {
            return
          }
         if (min != null && val < min) {
            return
          }


          setValue(event)
        }}
        value={formValues[name]}
      />
      {renderErrors}
    </div>

  )
}

export default DigitInput
