import Button from "../button/Button"
import Modal from "./Modal"




const DeleteModal = ({ text, deleteCallback, closeModal}) =>{


  return (
    <Modal
      showHadler={closeModal}
    >


      <div className="flex flex-col gap-7">



        <h2
          className="text-xl"
        >Eliminazione</h2>

        <p>{text}</p>



        <div className="flex w-full justify-around">


          <Button 
            text="Elimina"
            onclick={deleteCallback}
          />


          <Button 
            text="Annulla"
            onclick={closeModal}
          />



        </div>
      </div>



    </Modal>
  )
}

export default DeleteModal
