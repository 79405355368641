import { useEffect, useReducer, useState } from 'react'
import { FAMIGLA_PRODOTTO_URL, FAMIGLA_URL, UM_URL } from '../../Costants'
import { useDelete, usePost, usePut, getValueFromItem } from '../../Hooks'
import Button from '../button/Button'
import ErrorLabel from '../error/ErrorLabel'
import TextInput from '../input/TextInput'
import Modal from '../wrap/Modal'
import ModalBtnGroup from '../misc/ModalBtnGroup'

const requestReducer = (old, action) => {
  switch (action.key) {
    case 'SET_UP':
      return {
        ...old,
        tmp_url: action.url,
        single_header: action.header
      }
    case 'UPDATE':
      return {
        ...old,
        update_url: old.tmp_url,
        body: action.body
      }
    case 'DELETE':
      return {
        ...old,
        delete_url: action.url
      }

    case 'CREATE':
      return {
        ...old,
        create_url: old.tmp_url,
        body: action.body
      }

    default:
      return old
  }
}

const initailReducerValue = {
  tmp_url: null,
  update_url: null,
  delete_url: null,
  create_url: null,
  body: null,
  single_header: ''
}

const SettingsModal = ({ showHadler, item, modelData, respCallback, action = null }) => {
  const [value, setValue] = useState(() => {

    return {

       value:getValueFromItem(item)
    }
  })
  const [reqState, dispatch] = useReducer(requestReducer, initailReducerValue)

  const setByEvent = (event) => {
    const val = event.target.value
    setValue(val)
  }

  const [error, setError] = useState(null)

  const putState = usePut({ url: reqState.update_url, query: reqState.body })
  const delState = useDelete({ url: reqState.delete_url })
  const postState = usePost({ url: reqState.create_url, query: reqState.body })

  const create = () => {
    dispatch({ key: 'CREATE', body:  value  })
  }

  const update = () => {
    dispatch({ key: 'UPDATE', body: { id: item.id, ...value } })
  }
  const del = () => {
    dispatch({ key: 'DELETE', url: new URL(item.id, reqState.tmp_url) })
  }

  useEffect(() => {
    dispatch({ key: 'SET_UP', url: modelData.url })
  }, [])

  useEffect(() => {
    if (putState.data != null) {
      respCallback(putState.data)
    } else if (postState.data != null) {
      respCallback(postState.data)
    } else if (delState.data != null) {
      respCallback(delState.data)
    }
  }, [putState.data, postState.data, delState.data])

  useEffect(() => {
    if (putState.error != null) {
      setError(putState.error)
    } else if (postState.error != null) {
      setError(postState.error)
    } else if (delState.error != null) {
      setError(delState.error)
    }
  }, [putState.error, postState.error, delState.error])

  const setValueByEvent = (event) => {
    const val = event.target.value
    setValue({ value: val })
  }

  return (

    <Modal
      showHadler={showHadler}
    >
      <div
        className='flex flex-col justify-center items-center gap-4'
      >

        <h2 className='text-xl'>{modelData.single_header}</h2>

        <TextInput
          formValues={value}
          name="value"
          setValue={setValueByEvent}
        />

        <div
          className='flex justify-around w-full'
        >

          <ModalBtnGroup
            isLoading={delState.loading || postState.loadint || putState.loading}
            delCallback={del}
            updateCallback={update}
            createCallback={create}
            action={action}
          />

        </div>
      </div>

    </Modal>
  )
}

// {
//   error != null &&
//     <ErrorLabel error={error}/>
// }

export default SettingsModal
