import { useEffect, useMemo, useState, useTransition } from 'react'
import { MOVIMENTI_URL } from '../Costants'
import { useGet } from '../Hooks'
import Button from '../views/button/Button'
// import MovimentiFilter from '../views/carico/MovimentiFilter'
import Table from '../views/table/Table'
// import MovimentiModal from '../views/carico/MovimentiModal'
import Loader from '../views/loader/Loader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MovimentiFilter from '../views/movimenti/MovimentiFilter'

const MovimentiPage = () => {

  const navigate = useNavigate()


  const [isPending, startTransition] = useTransition()

  const [showAnModal, setShowAnModal] = useState(false)
  const [action, setAction] = useState(null)
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)

  const [getData, setGetData] = useState({ url: null })
  const getState = useGet(getData)

  const filterCallback = (values) => {
    const params = new URLSearchParams()
    for (const key of Object.keys(values)) {
      if (values[key] != null && values[key].toString().trim() !== '') {
        params.append(key, values[key])
      }
    }
    const url = new URL(MOVIMENTI_URL)
    url.search = params.toString()
    setGetData({ url })
  }

  const rowCallback = (some) => {


    navigate("/movimento/edit", {state:{
      id:some,
      from:"/movimenti"
    }})
    // setSelectedItem(some)
    // setShowAnModal(true)
  }

  const respCallback = (obj) => {
    if (typeof obj === 'object') {
      if (action === 'create') {
      // Create new
        setItems(old => {
          old.push(obj)
          return [...old]
        })
      } else {
        // Update
        setItems(old => {
          let oldIdx = 0
          const newItems = old.filter(ele => {
            if (ele.id == obj.id) {
              oldIdx = old.indexOf(ele)
              return false
            }
            return true
          })
          newItems.splice(oldIdx, 0, obj)

          return [...newItems]
        })
      }
    } else {
      // Delete
      setItems(old => {
        const newItems = old.filter(ele => ele.id !== obj)
        return [...newItems]
      })
    }

    setAction(null)
    setShowAnModal(false)
  }

  useEffect(() => {
    setGetData({ url: MOVIMENTI_URL })
    return () => {
      setGetData({ url: null })
    }
  }, [])

  useEffect(() => {
    if (getState.data != null) {
      startTransition(() => {
        setItems(getState.data)
      })
    }
  }, [getState.data])

  const renderContent = useMemo(() => {
    if (getState.loading === true || isPending === true) {
      return <Loader/>
    } else {
      return (
        <>
          <Table
            items={items}
            rowCallback={rowCallback}
          />

               </>
      )
    }
  }, [items, isPending, getState.loading])

  return (
    <main
      className='flex flex-col items-center justify-center'
    >

      <MovimentiFilter
        filterCallback={filterCallback}
      />

      {
        renderContent
      }


    </main>
  )
}

export default MovimentiPage
