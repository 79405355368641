import { useCallback, useEffect, useMemo, useState, useTransition } from 'react'
import { INVENTARI_URL, } from '../Costants'
import { useGet } from '../Hooks'
import Table from '../views/table/Table'
import Loader from '../views/loader/Loader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import InventarioRow from '../views/inventari/InventarioRow'
import DeleteInventarioModal from '../views/inventari/DeleteInventarioModal'




const DownloadInventariPage = () => {

  const [isPending, startTransition] = useTransition()
  const [ showModal, setShowModal ] = useState(false)
  const [ selectedItem, setSelectedItem ] = useState(null)

  const [items, setItems] = useState([])

  const [getData, setGetData] = useState({ url: null })
  const getState = useGet(getData)

 
 
  useEffect(() => {
    setGetData({ url: INVENTARI_URL })
    return () => {
      setGetData({ url: null })
    }
  }, [])

  useEffect(() => {
    if (getState.data != null) {

      setGetData({url:null})
      startTransition(() => {
        setItems(getState.data)
      })
    }
  }, [getState.data])


  const selectCallback = useCallback((inv) => {

    setSelectedItem(inv)
    setShowModal(true)
  })

  const renderContent = useMemo(() => {
    if (getState.loading === true || isPending === true) {
      return <Loader/>
    } else {
      return items.map(inv => {
        return (
          <InventarioRow
          inventario={inv}
            selectCallback = {selectCallback}
            key={inv.id}
          />
        )
      })
    }
  }, [items, isPending, getState.loading])

  return (
    <main
      className='flex flex-col items-center justify-center'
    >

      <ul
        className="list-none w-full p-6"
      >
        { renderContent }
      </ul>


    {
      showModal &&
        <DeleteInventarioModal
          showHandler={() => {
            setGetData({ url: INVENTARI_URL })
            setShowModal(false)
          }}
          inventario={selectedItem}
      />
    }
    </main>
  )
}


export default DownloadInventariPage
