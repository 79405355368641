import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DataContext } from "../../context/DataContext";
import { ErrorContext } from "../../context/ErrorContex";
import { getValueFromItem, useGet, useModelData } from "../../Hooks";

const Select = ({
  name,
  setValue,
  formValues,
  setSemaphore,
  optionCallback = null,
  propErrors = {},
  disabled = false,
  label = null,
  showLabel = true,
}) => {
  const modelData = useModelData(name);

  const [appData, setAppData] = useContext(DataContext);

  const [options, setOptions] = useState([]);
  const [getData, setGetData] = useState({ url: null });
  const getState = useGet(getData);

  const optionsRender = useMemo(() => {
    return options.map((opt, idx) => {
      const text = getValueFromItem(opt);
      return (
        <option
          key={`${opt.id}_${idx}`}
          value={opt.id}
          className="dark:bg-dark-2"
        >
          {text}
        </option>
      );
    });
  }, [options]);

  const buildOptions = (res) => {
    if (optionCallback != null) {
      res = optionCallback(res);
    }

    const endRes = structuredClone(res)
    endRes.unshift({ [modelData.type]: "Scegli un opzione", id: null });
    setOptions(endRes);
    if (setSemaphore != null) {
      setSemaphore((old) => {
        return {
          ...old,
          [name]: true,
        };
      });
    }
  };

  useLayoutEffect(() => {
    if (modelData.name in appData) {
      buildOptions(appData[modelData.name]);
    } else {
      setGetData({ url: modelData.url });
    }

    return () => {
      setGetData({ url: null });
    };
  }, [modelData.url, modelData.name, appData]);

  useEffect(() => {
    if (getState.data != null) {
      const res = getState.data;

      setAppData((old) => {
        return {
          ...old,
          [modelData.name]: getState.data,
        };
      });
      buildOptions(res);
    }

    return () => {
      setGetData({ url: null });
    };
  }, [getState.data, modelData.name]);

  useEffect(() => {
    if (name === "stato") {
      const values = [
        { id: null, stato: "Scegli un opzione" },
        { id: "magazzino", stato: "magazzino" },
        { id: "venduto", stato: "venduto" },
        { id: "lavorazione", stato: "lavorazione" },
      ];
      setOptions(values);
    }
  }, []);

  const [errors] = useContext(ErrorContext);

  const renderErrors = useMemo(() => {
    const err = propErrors[name] ?? errors[name];
    return err?.map((errMsg, idx) => {
      return (
        <label
          key={`select_err_${idx}`}
          className="mb-[3px] block text-sm text-red-500 text-start "
        >
          {errMsg}
        </label>
      );
    });
  }, [errors[name], propErrors]);

  const selRef = useRef();

  useEffect(() => {
    if (formValues[name] == null) {
      selRef.current.value = "Scegli un opzione";
    }
  }, [formValues[name]]);

  return (
    <div className="px-4 w-full min-w-[200px] sm:max-w-[40%]  md:max-w-[30%]  lg:max-w-[300px]">
      {showLabel === true && (
        <label className="mb-[3px] block text-sm text-blue-800 text-start ">
          {label ?? modelData.label}
        </label>
      )}

      <div
        className="relative  w-full bg-text-bg rounded-md border border-stroke border-blue-800 dark:border-dark-3 p-[5px] pr-8 text-dark-6 outline-none transition  active:border-amber-500 disabled:cursor-default disabled:bg-gray-2 h-10
focus:border-amber-500"
      >
        <select
          name={name}
          ref={selRef}
          disabled={disabled}
          value={formValues[name]}
          onChange={(event) => {
            const val = event.target.value;
            if (val.includes("Scegli")) {
              event.target.value = null;
            }
            setValue(event);
          }}
          className="absolute top-0 left-0 m-h-5 h-full w-full bg-transparent pl-4  active:border-amber-500 focus:border-amber-500"
        >
          {optionsRender}
        </select>
      </div>
      {renderErrors}
    </div>
  );
};

export default Select;
