import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataContext";
import { MOVE_CARICO_URL } from "../../Costants";
import { useForm, usePost } from "../../Hooks";
import Button from "../button/Button";
import DigitInput from "../input/DigitInput";
import Select from "../input/Select";
import Loader from "../loader/Loader";
import { FaCheck } from "react-icons/fa6";

const CaricoRow = ({ carico }) => {
  const [appData] = useContext(DataContext);
  const [showCheck, setShowCheck] = useState(false);

  const [postData, setPostData] = useState({ url: null, query: null });
  const postState = usePost(postData);

  const moveCarico = () => {
    const body = {
      id: carico.id,
      ...formValues,
    };

    setPostData({ url: MOVE_CARICO_URL, query: body });
  };

  const getMagazzinoId = () => {
    return appData.magazzino_id?.find(
      (mag) => mag.cod_magazzino === carico["cod. magazzino"],
    )?.id;
  };

  const [formValues, setFormValues, setFormValuesByEvent, setFormValuesByName] =
    useForm({
      numero_cavalletto: carico["numero cavalletto"],
      magazzino_id: getMagazzinoId(),
    });

  useEffect(() => {
    if (postState.data != null && postState.data.success === "ok") {
      setShowCheck(true);
      setTimeout(() => setShowCheck(false), 1000);
    }
  }, [postState.data]);

  return (
    <li className="w-full flex justify-between items-end my-5">
      <span>{carico["cod. pr. anagrafica"]}</span>

      <Select
        name="magazzino_id"
        formValues={formValues}
        setValue={setFormValuesByEvent}
        showLabel={false}
      />

      <DigitInput
        name="numero_cavalletto"
        formValues={formValues}
        setValue={setFormValuesByEvent}
      />

      {showCheck === true ? (
        <i className="text-green-500 text-4xl">
          <FaCheck />
        </i>
      ) : postState.loading === true ? (
        <Loader />
      ) : (
        <Button text="Sposta" onclick={() => moveCarico()} />
      )}
    </li>
  );
};

export default CaricoRow;
