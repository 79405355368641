import { useEffect, useRef, useState } from 'react'
import { useForm } from '../../Hooks'
import Select from '../input/Select'
import TextInput from '../input/TextInput'
import SearchInput from '../input/SearchInput'
// import CambioMagazzinoModal from './CambioMagazzinoModal'
import DigitInput from '../input/DigitInput'
import CheckBox from '../input/CheckBox'
import DateInput from '../input/DateInput'

const CambioMagazzinoForm = ({
  formValues,
  setFormValuesByEvent,
  setFormValuesByName,
  selectedParent,
}) => {
  const initialRef = useRef(true)

  const [semaphore, setSemaphore] = useState({
    magazzino_id: false
  })

  // useEffect(() => {
  //   if (Object.values(semaphore).every(bool => bool) && initialRef.current === true && values.id !== '') {
  //     initialRef.current = false
  //     setFormValues(values)
  //   }
  // }, [values, semaphore])

  // useEffect(() => {
  //   setValues(formValues)
  // }, [formValues])
  //



  return (

    <form
      className='flex flex-wrap gap-3 overflow-y-auto h-full'
    >

      <Select
        name='magazzino_id'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        setSemaphore={setSemaphore}
        label="Cod. magazzino dest."
      />

      <TextInput
        name='ubicazione_scaffale'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        placeholder='Ubicazione scaffale'
      />



      <DigitInput
        name='numero_cavalletto'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        placeholder='Numero cavalletto'
      />

    </form>
  )
}

export default CambioMagazzinoForm

// <Select
//   name='scelta_id'
//   formValues={formValues}
//   setValue={setFormValuesByEvent}
//   setSemaphore={setSemaphore}
// />
// <TextInput
//   name='id_tag'
//   formValues={formValues}
//   setValue={setFormValuesByEvent}
//   placeholder='ID Tag'
// />
