import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSessionStorageState from 'use-session-storage-state'
import { LOGIN_URL } from '../Costants'
import { useForm, usePost } from '../Hooks'
import Button from '../views/button/Button'
import TextInput from '../views/input/TextInput'
import Loader from '../views/loader/Loader'

const LoginPage = ({}) => {

  const navigate = useNavigate()
  const [, setUser] = useSessionStorageState('user')

  const [
    formValues,
    ,
    setFormValuesByEvent
  ] = useForm({
    username: '',
    passsword: ''
  })

  const [postData, setPostData] = useState({ url: null, query: null })
  const postState = usePost(postData)

  const do_login = () => {
    setPostData({ url: LOGIN_URL, query: formValues })
  }

  useEffect(() => {
    return () => {
      setPostData({ url: null, query: null })
    }
  }, [])

  useEffect(() => {
    if (postState.data != null) {

      setUser({login:true})
      navigate('/carichi')
    } else if (postState.error != null) {
      setPostData({ url: null, query: null })
    }
  }, [postState.data, postState.error])

  return (

    <div
      className='w-[100vw] h-[100vh] flex justify-center items-center gap-6 flex-col'
    >

      <TextInput
        formValues={formValues}
        setValue={setFormValuesByEvent}
        name='username'
        placeholder='Utente'
      />

      <TextInput
        formValues={formValues}
        setValue={setFormValuesByEvent}
        name='password'
        placeholder='Password'
      />

      {
        postState.error != null &&
          <label className='text-red-500 text-sm'>Credenziali sbagliate</label>
      }

      {
        postState.loading !== true
          ? <Button
              text='Entra'
              onclick={do_login}
            />

          : <Loader />

      }

    </div>
  )
}

export default LoginPage
