import { useMemo } from "react"



const PelleDetails = ({data, horizontal }) => {


  const renderDetails = useMemo(() => {
    return Object.entries(data).map(([key,val], idx) => {
      return (
        <li
          className="pb-1"
          key={`${key}_${idx}`}
        >
          <p>
          <span 
              className="font-boldt text-orange-600"
          >{key}: </span>

            <span
              className="select-text text-black"
            >
            {val}
            </span>
          </p>
        </li>
      )
    })
  }, [data]) 


  const possibleClasses = [
    "grid"
  ]

  return (

    <ul
      className="list-none text-left p-9 rounded-md w-[80%] "
    >
      {renderDetails}
    </ul>
  )
}


export default PelleDetails
