import { createContext, useState } from "react";



export const ErrorContext = createContext()



export const ErrorContextProvider = ({children}) =>{



  const [errors, setErrors ] = useState({})



  return (

  <ErrorContext.Provider value={[errors, setErrors]}> {children} </ErrorContext.Provider>


  )
}


