import { useMemo } from 'react'

const KeysError = ({ keysErrors }) => {
  const renderErrors = useMemo(() => {
    return Object.keys(keysErrors).map((type, idx) => {
      return (

        <li
          key={type}
          className="w-full"
        >
          <span
            className="text-lg text-red-500"
          >{ `${type.replaceAll('_',' ' )}:`} </span>
          <p
            className="break-all pl-2"
          >
            {keysErrors[type].toString()}
          </p>

        </li>
      )
    })
  }, [keysErrors])

  return (


    <div>
      <p className='text-left'>
        <span className='text-red-500'>ERRORE:</span> Impossibile importare il file per 
        mancaza dei seguenti campi asssociati, crearli e riprovare.
      </p>

    <ul

      className="bg-white list-none w-full text-start overflow-y-auto p-3 "
    >

      {renderErrors}

    </ul>
    </div>
  )
}

export default KeysError
