import { useMemo } from "react";
import {
  MIDIUM_COL,
  NOT_SHOW_COL,
  ROUNDED_COL,
  SMALL_COL,
} from "../../Costants";
import TableItem from "./TableItem";

const TableRow = ({ row, header, rowCallback, type, isDark }) => {
  const tableItems = useMemo(() => {
    return Object.entries(row)
      .filter(([key, val]) => !NOT_SHOW_COL.includes(key))
      .map(([key, val], idx) => {
        const obj = header !== true ? val : key;
        return (
          <TableItem
            key={`${type}_col_${idx}_${row.id}`}
            content={obj}
            isSmall={SMALL_COL.includes(key)}
            isMidium={MIDIUM_COL.includes(key)}
            isDark={isDark}
            isHeader={header}
            isRounded={ROUNDED_COL.includes(key)}
            stato={row?.stato}
          />
        );
      });
  }, [row]);

  return (
    <tr
      className="border border-r-none border-black min-h-[50px] flex  "
      onClick={() => {
        if (rowCallback != null) {
          rowCallback(row.id);
        }
      }}
    >
      {tableItems}
    </tr>
  );
};

export default TableRow;
