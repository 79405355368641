




const Button = ({ text, onclick }) => {



  return (

    <button
      className="bg-primary text-white rounded-md px-5 py-2"
      onClick={onclick}
    >
      
      {text}
    </button>
  )


}

export default Button
