import { useEffect } from "react"
import { createPortal } from "react-dom"

import './Modal.css'


const ModalWrap = ({ children }) => {

	return createPortal(children, document.body )
}



const Modal = ({ children, showHadler }) => {


	return (
		<ModalWrap>
			<div 
				className='modal-back'
				onClick={ (event) => {
					event.stopPropagation()
					showHadler( false )
				}}
			>
				<div
					className='modal-card overflow-y-auto'
					onClick={ event => {
						event.stopPropagation()
					}}
				>

					{children}

				</div>
			</div>
		</ModalWrap>
	)
}


export default Modal
