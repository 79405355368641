import { useContext, useMemo, useState } from 'react'
import { IoMdSearch } from 'react-icons/io'

import { ErrorContext } from '../../context/ErrorContex'
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5'
import SmallLoader from '../loader/SmallLoader'

const TextInput = ({
  setValue,
  formValues,
  name,
  placeholder,
  uppercase = false,
  label = null,
  isSearch = false,
  isLoading = false,
  propErrors = {},
  large = false,
  disabled = false
}) => {
  const [errors] = useContext(ErrorContext)

  const [showPass, setShowPass] = useState(name === 'password')

  const renderErrors = useMemo(() => {
    const err = propErrors[name] ?? errors[name]
    return err?.map((errMsg, idx) => {
      return (
        <label
          key={`text_err_${idx}`}
          className='mb-[3px] block text-sm text-red-500 text-start '
        >
          {errMsg}
        </label>

      )
    })
  }, [errors[name], propErrors])

  const possibleCls = [
    'min-w-[250px]'
  ]

  return (

    <div className={`relative w-full px-4 min-w-[200px] ${large && 'min-w-[250px] '}  ${!isSearch && 'sm:max-w-[40%]'}   ${!isSearch && 'md:max-w-[30%]'}  lg:max-w-[300px] `}>
      <label className='mb-[3px] block text-sm text-blue-800 text-start '>
        {label != null ? label : placeholder}
      </label>

      <div className='relative'>

        <input
          type={showPass ? 'password' : 'text'}
          placeholder={placeholder}
          name={name}
          className='w-full bg-text-bg rounded-md border border-stroke
                  border-blue-800
                  dark:border-dark-3 py-1 px-2 text-dark-6
                  outline-none transition active:border-primary
                  disabled:cursor-default disabled:bg-gray-2
                  focus:border-amber-500'

          onChange={(event) => {
            if (uppercase === true) {
              const txt = event.target.value
              event.target.value = txt.toUpperCase()
            }
            setValue(event, true)
            // const val = event.target.value
            // if ( isNaN(val) || val === '' ){
            //   setValue(val)
            // } else {
            //   setValue(Number(val))
            // }
          }}
          value={formValues[name]}
          autoComplete='false'
          disabled={disabled}
        />

        {

           name === 'password' && (

             showPass === true
               ? <i
                   className='absolute right-[25px] bottom-[9px] '
                   onClick={() => setShowPass(old => !old)}
                 >
                 <IoEyeOutline />
               </i>
               : <i
                   className='absolute right-[25px] bottom-[9px] '
                   onClick={() => setShowPass(old => !old)}
                 >
                 <IoEyeOffOutline />
               </i>)

        }

        {
        isSearch === true && (

          isLoading === false
            ? <i className='absolute right-[25px] bottom-[9px] '>
              <IoMdSearch />
              </i>

            : <i className='absolute right-[25px] bottom-[9px] '>
              <SmallLoader />
              </i>)

      }

      </div>

      {renderErrors}

    </div>

  )
}

export default TextInput
