import { useDebounce, useForm } from '../../Hooks'
import Select from '../input/Select'
import CheckBox from '../input/CheckBox'

import useSessionStorageState from 'use-session-storage-state'
import Slider from '../wrap/Slider'
import { useEffect } from 'react'
import Button from '../button/Button'

const initialValues = {
  famiglia: false,
  famiglia_prodotto: false,
  magazzino: false,
  scelta: false,
  trattamento: false
}

const GiacenzeGroupBy = ({ filterCallback }) => {
  const [
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormValuesByName
  ] = useForm(initialValues)

  const debValue = useDebounce(formValues, 400)

  useEffect(() => {
    filterCallback(debValue)
  }, [debValue])

  return (

    <div
      className='w-[90vw]'
    >

      <Slider
        header='RAGRUPPA'
      >

        <div
          className='relative flex flex-wrap gap-7 p-3'
        >

          <Button
            text='annulla ragruppamenti'
            onclick={() => setFormValues(initialValues)}
          />

          <CheckBox

            formValues={formValues}
            setValue={ (value) => setFormValuesByName("magazzino", value)}
            name='magazzino'
            label="codice magazzino"
          />
          <CheckBox
            formValues={formValues}
            setValue={ (value) => setFormValuesByName("scelta", value)}
            name='scelta'
            label='scelta'
          />
          <CheckBox
            formValues={formValues}
            setValue={ (value) => setFormValuesByName("trattamento", value)}
            name='trattamento'
            label='trattamento'
          />


        </div>

      </Slider>

    </div>
  )
}



          // <CheckBox
          //   formValues={formValues}
          //   setValue={ (value) => setFormValuesByName("famiglia", value)}
          //   name='famiglia'
          //   label='famiglia'
          // />
          // <CheckBox
          //   formValues={formValues}
          //   setValue={ (value) => setFormValuesByName("famiglia_prodotto", value)}
          //   name='famiglia_prodotto'
          //   label='famiglia prodotto'
          // />
          //
//  <Select
//    setValue={(val) => setFormValuesByName('magazzino', val)}
//    name='magazzino'
//    value={formValues.magazzino}
//  />
//  <Select
//    setValue={(val) => setFormValuesByName('scelta', val)}
//    name='scelta'
//    value={formValues.scelta}
//  />
//  <Select
//    setValue={(val) => setFormValuesByName('trattamento', val)}
//    name='trattamento'
//    value={formValues.trattamento}
//  />
//
// <Select
//    setValue={(val) => setFormValuesByName('famiglia', val)}
//    name='famiglia'
//    value={formValues.famiglia}
//  />
//  <Select
//    setValue={(val) => setFormValuesByName('famiglia_prodotto', val)}
//    name='famiglia_prodotto'
//    value={formValues.famiglia_prodotto}
//  />
//  <Select
//    setValue={(val) => setFormValuesByName('um', val)}
//    name='um'
//    value={formValues.um}
//  />
//
//
//
// <Select
//   formValues={formValues}
//   setValue={setFormValuesByEvent}
//   name='stato'
//   label="stato"
// />

export default GiacenzeGroupBy
