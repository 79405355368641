import Button from '../button/Button'
import Loader from '../loader/Loader'

const ModalBtnGroup = ({ delCallback, updateCallback, createCallback, isLoading, action }) => {
  if (isLoading === true) {
    return <Loader />
  } else if (action === 'create') {
    return (
      <Button
        text='Crea'
        onclick={createCallback}
      />
    )
  } else {
    return (
      <>

        <Button
          text='Modifica'
          onclick={updateCallback}
        />

        <Button
          text='Elimina'
          onclick={delCallback}
        />

      </>
    )
  }
}

export default ModalBtnGroup
