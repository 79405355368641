import ModalBtnGroup from '../views/misc/ModalBtnGroup'
import { useDelete, useForm, useGet, useModelData, usePost, usePut } from '../Hooks'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import CaricoForm from '../views/carico/CaricoForm'
import { ErrorContext } from '../context/ErrorContex'
import PelleForm from '../views/carico/PelleForm'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DeleteModal from '../views/wrap/DeleteModal'

const requestReducer = (old, action) => {
  switch (action.key) {
    case 'SET_UP':
      return {
        ...old,
        tmp_url: action.url
      }
    case 'UPDATE':
      return {
        ...old,
        update_url: action.url,
        body: action.body
      }
    case 'DELETE':
      return {
        ...old,
        delete_url: action.url
      }

    case 'GET':
      return {
        ...old,
        get_url: action.url
      }

    case 'CREATE':
      return {
        ...old,
        create_url: old.tmp_url,
        body: action.body
      }

    default:
      return old
  }
}

const initailReducerValue = {
  tmp_url: null,
  update_url: null,
  delete_url: null,
  create_url: null,
  get_url: null,
  body: null
}

const DELETE_MODAL_TEXT = 'Stai per eliminare il carico selezionato e tutte le pelli associate, vuoi continuare?'

const CaricoFormPage = () => {
  const { action } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormValuesByName
  ] = useForm({
    id: '',
    magazzino_id: '',
    n_pelli: 1,
    trattamento_id: '',
    commessa: 99999,
    ordine: '',
    ubicazione_scaffale: '',
    numero_cavalletto: '',
    anagrafica_cod_pr: action === 'add' && location.state?.cod_prodotto != null ? location.state?.cod_prodotto : '',
    pelli: [{
      dimensione: '',
      scelta_id: '',
      id_tag: ''
    }],
    pelliDisabled:true,

  })

  const [tmpPelli, setTmpPelli] = useState([])
  // const [pelliDisabled, setPelliDisabled] = useState(true)

  const modelData = useModelData('carico')

  const [showDeleteModal, setShowDeleteModal] = useState()

  const [reqState, dispatch] = useReducer(requestReducer, initailReducerValue)

  const [, setErrors] = useContext(ErrorContext)

  const [pelliErrors, setPelliErrors] = useState([])

  const putState = usePut({ url: reqState.update_url, query: reqState.body })
  const delState = useDelete({ url: reqState.delete_url })
  const postState = usePost({ url: reqState.create_url, query: reqState.body })
  const getState = useGet({ url: reqState.get_url })

  const genPelliBody = () => {
    return formValues.pelli.map((pelle) => {
      return Object.fromEntries(
        Object.entries(pelle).filter(([key, val]) => val !== '')
      )
    })
  }

  const genDetailModelUrl = () => {
    const item_id = location.state.id
    return new URL(item_id, modelData.url)
  }

  const create = () => {
    const pelli = genPelliBody()
    const body = {
      ...formValues,
      pelli: [...pelli]
    }

    dispatch({ key: 'CREATE', body })
  }

  const update = () => {
    const url = genDetailModelUrl()
    const pelli = genPelliBody()
    const body = {
      ...formValues,
      pelli: [...pelli]
    }
    dispatch({ key: 'UPDATE', body, url })
  }

  const del = () => {
    const url = genDetailModelUrl()
    dispatch({ key: 'DELETE', url })
  }

  useEffect(() => {
    if (putState.data != null) {
      navigate('/carichi')
    } else if (postState.data != null) {
      navigate('/carichi')
    } else if (delState.data != null) {
      navigate('/carichi')
    } else if (getState.data != null) {
      dispatch({ key: 'SET_UP', url: modelData.url })
      const values = getState.data
      values["pelliDisabled"] = true 
      setFormValues(values)

      setTmpPelli(getState.data.pelli)
    }
  }, [putState.data, postState.data, delState.data, getState.data])

  const handleErrors = (errData, errType) => {
    setErrors(errData ?? {})

    if (errType === 'unique violation') {
      const errArray = new Array(formValues?.pelli?.length).fill({})
      formValues?.pelli?.forEach((pelle, idx) => {
        if (errData.includes(pelle.id_tag)) {
          errArray[idx] = { id_tag: ['ID Tag già esistente'] }
        }
      })
      setPelliErrors(errArray)
    } else {
      setPelliErrors(errData?.pelli ?? [])
    }
  }

  useEffect(() => {
    if (putState.error != null) {
      const errData = putState.error.data
      const errType = putState.error.type
      handleErrors(errData, errType)
    } else if (postState.error != null) {
      const errData = postState.error.data
      const errType = postState.error.type
      handleErrors(errData, errType)
    } else if (delState.error != null) {
      setErrors(delState.error)
    } else if (getState.error != null) {
      // setValues(getState.error)
    }
  }, [putState.error, postState.error, delState.error, getState.error])

  useEffect(() => {
    if (action === 'add') {
      dispatch({ key: 'SET_UP', url: modelData.url })
      return
    }
    if (modelData.type == null) return

    const item_id = location.state.id
    const url = new URL(item_id, modelData.url)
    dispatch({ key: 'GET', url })
  }, [
    action,
    modelData.type
    // item
  ])
  //
  useEffect(() => {
    return () => {
      setErrors({})
      setPelliErrors([])
    }
  }, [])

  useEffect(() => {
    setFormValues(old => {
      if (old.n_pelli > old.pelli.length) {
        const newPelli = [...old.pelli]

        let res = newPelli.concat(
          tmpPelli.slice(newPelli.length, old.n_pelli  )
        )


        const diff = old.n_pelli - res.length

        if (diff > 0) {
          const tmp_arr = new Array(diff).fill({
            dimensione: '',
            scelta_id: '',
            id_tag: ''
          })

          res = res.concat(tmp_arr)
        }

        return {
          ...old,
          pelli: [...res]
        }
      } else if (old.n_pelli < old.pelli.length) {
        const newPelli = [...old.pelli]
        const diff = Math.abs(old.n_pelli - old.pelli.length)
        newPelli.splice(-diff, diff)
        return {
          ...old,
          pelli: [...newPelli]
        }
      } else { return old }
    })
  }, [formValues.n_pelli])

  const renderPelliForm = () => {
    return formValues?.pelli.map((x, idx) => {
      // console.log(idx)
      //
      // console.log("formValues?.pelli[idx]", formValues?.pelli[idx])
      // console.log("formValues.pelli", formValues?.pelli)
      return (
        <PelleForm
          formValues={x}

          disabled={formValues.pelliDisabled}
          setFormValuesByEvent={event => {
            const name = event.target.name
            let val = event.target.value

            if (!isNaN(val) && val !== '') {
              val = Number(val)
            }

            setFormValues(old => {
              const newPelli = [...old.pelli]
              // console.log( "newPelli", newPelli )

              const currPelle = { ...newPelli.at(idx) }

              // console.log(currPelle)

              currPelle[name] = val

              // console.log(currPelle)
              newPelli.splice(idx, 1, currPelle)

              // console.log( "newPelli", newPelli )

              //
              // const tmp_pelle_form =   newPelli.at(idx)
              //
              // // console.log(idx)
              // // console.log("new val ->", newPelli[idx][name])
              // // console.log("tmp_pelle_form", tmp_pelle_form)
              // tmp_pelle_form[name] = val
              //
              // // console.log("tmp_pelle_form UPDATED", tmp_pelle_form)
              // newPelli.splice(idx, 1, {...x})
              // console.log( "newPelli UPDATED", newPelli )
              return {
                ...old,
                pelli: newPelli
              }
            })
          }}

          setFormValuesByName={(name, val) => {
            setFormValues(old => {
              const newPelli = [...old.pelli]
              const currPelle = { ...newPelli.at(idx) }
              currPelle[name] = val
              newPelli.splice(idx, 1, currPelle)
              return {
                ...old,
                pelli: newPelli
              }
            })
          }}
          idx={idx}

          removeSelf={() => {
            setFormValues(old => {
              const newPelli = [...old.pelli]
              newPelli.splice(idx, 1)
              return {
                ...old,
                n_pelli: old.n_pelli - 1,
                pelli: [...newPelli]
              }
            })
          }}

          key={`pelle_${idx}`}

          errors={pelliErrors[idx] ?? {}}
        />
      )
    })
  }
  // , [
  //   formValues?.pelli,
  //   formValues?.pelli.length,
  //   setFormValues,
  //   pelliErrors
  //
  // ])






  return (
    <main>

      <div
        className='flex flex-col justify-center items-center gap-5 h-full pb-5'
      >

        <h2 className='text-xl'>{
          action === 'add' ? `Crea nuovo ${modelData.single_header}` : `${modelData.single_header} ${location.state.id}`
        }
        </h2>

        <CaricoForm
          formValues={formValues}
          setFormValuesByName={setFormValuesByName}
          setFormValuesByEvent={setFormValuesByEvent}
        />

        {renderPelliForm()}

        <div
          className='flex justify-around w-full mt-5'
        >

          <ModalBtnGroup

            isLoading={delState.loading || postState.loading || putState.loading}
            delCallback={() => setShowDeleteModal(true)}
            updateCallback={update}
            createCallback={create}
            action={action === 'add' ? 'create' : action}
          />

        </div>
      </div>

      {
        showDeleteModal === true &&
          <DeleteModal

            closeModal={() => setShowDeleteModal(false)}
            deleteCallback={del}
            text={DELETE_MODAL_TEXT}
          />
      }

    </main>

  )
}

export default CaricoFormPage
