import { useDebounce, useForm } from '../../Hooks'
import Select from '../input/Select'
import SearchInput from '../input/SearchInput'

import useSessionStorageState from 'use-session-storage-state'
import Slider from '../wrap/Slider'
import Button from '../button/Button'
import { useEffect } from 'react'



const initialValues = {
    search: '',
    famiglia_id: null,
    famiglia_prodotto_id: null,
    um_id: null,
    magazzino_id: null,
    // scelta_id: null,
    trattamento_id: null
}




const CaricoFilter = ({ filterCallback }) => {
  const [
    formValues,
    setFormValues,
    setFormValuesByEvent,
    setFormValuesByName
  ] = useForm(initialValues)

  const debValue = useDebounce(formValues, 400)

  useEffect(() => {
    filterCallback(debValue)
  }, [debValue])

  return (

    <div
      className='w-[90vw]'
    >

      <div 
        className = "flex gap-6 items-end"
      >
      <SearchInput

        formValues={formValues}
        setValue={null}
        setValueByEvent={setFormValuesByEvent}
        name='search'

      />


        <Button
          text= "cancella filtri"
          onclick={ () => setFormValues(initialValues)}
          />

      </div>
      <Slider
        header='FILTRI'
      >

        <div
          className='relative flex flex-wrap gap-7 p-3'
        >

          <Select
            formValues={formValues}
            setValue={setFormValuesByEvent}
            name='magazzino_id'
          />
          <Select
            formValues={formValues}
            setValue={setFormValuesByEvent}
            name='trattamento_id'
          />

          <Select
            formValues={formValues}
            setValue={setFormValuesByEvent}
            name='famiglia_id'
          />
          <Select
            formValues={formValues}
            setValue={setFormValuesByEvent}
            name='famiglia_prodotto_id'
          />
          <Select
            formValues={formValues}
            setValue={setFormValuesByEvent}
            name='um_id'
          />

        </div>

      </Slider>

    </div>
  )
}

//  <Select
//    setValue={(val) => setFormValuesByName('magazzino', val)}
//    name='magazzino'
//    value={formValues.magazzino}
//  />
//  <Select
//    setValue={(val) => setFormValuesByName('scelta', val)}
//    name='scelta'
//    value={formValues.scelta}
//  />
//  <Select
//    setValue={(val) => setFormValuesByName('trattamento', val)}
//    name='trattamento'
//    value={formValues.trattamento}
//  />
//
// <Select
//    setValue={(val) => setFormValuesByName('famiglia', val)}
//    name='famiglia'
//    value={formValues.famiglia}
//  />
//  <Select
//    setValue={(val) => setFormValuesByName('famiglia_prodotto', val)}
//    name='famiglia_prodotto'
//    value={formValues.famiglia_prodotto}
//  />
//  <Select
//    setValue={(val) => setFormValuesByName('um', val)}
//    name='um'
//    value={formValues.um}
//  />
//
          // <Select
          //   formValues={formValues}
          //   setValue={setFormValuesByEvent}
          //   name='scelta_id'
          // />
//

export default CaricoFilter
