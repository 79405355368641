
const genBaseUrl = () => {
	if ( window.location.port === '3000'){
		// return new URL('http://192.168.178.48:8000/api/')
		// return new URL('http://starmenu.it:8000/api/')
		return new URL('http://dev.foglizzo.it:8000/api/')
		// return new URL('http://192.168.1.111:8000/api/')
		//return new URL('http://localhost:8000/api/')
	}
	else{
		return new URL( `${window.location.origin}/api/`)
	}
	
}

const genBaseWsUrl = () => {

	const protocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
	if ( window.location.port === '3000'){
		// return new URL('ws://localhost:8000/')
		 return new URL(`${protocol}//dev.starmenu.it:8000/ws/`)
	}
	else{
		return new URL( `${protocol}//${window.location.hostname}:${window.location.port}/ws/`)
	}
}


export const BASE_URL = genBaseUrl()
export const BASE_WS = genBaseWsUrl()



export const STATIC_URL = new URL(  BASE_URL.href.replace("api/", "") )
export const LOGO_URL = new URL( 'logo/', STATIC_URL  )


export const ANAGRAFICA_URL = new URL( 'anagrafica/', BASE_URL )
export const PELLE_URL = new URL( 'pelle/', BASE_URL )
export const CAUSALE_MOVIMENTO_URL = new URL( 'causale_movimento/', BASE_URL )
export const CARICO_URL = new URL( 'carico/', BASE_URL )
export const MOVIMENTI_URL = new URL( 'movimenti/', BASE_URL )
export const GIACENZE_URL = new URL( 'movimenti/giacenze/', BASE_URL )
export const IMPORT_URL = new URL( 'anagrafica/import', BASE_URL )
export const FAMIGLA_URL = new URL( 'famiglia/',  BASE_URL )
export const FAMIGLA_PRODOTTO_URL = new URL( 'famiglia_prodotto/',  BASE_URL )
export const UM_URL = new URL( 'um/',  BASE_URL )
export const MAGAZZINO_URL = new URL( 'magazzino/',  BASE_URL )
export const SCELTA_URL = new URL( 'scelta/',  BASE_URL )
export const TRATTAMENTO_URL = new URL( 'trattamento/',  BASE_URL )
export const INVENTARI_URL = new URL( 'inventario/',  BASE_URL )
export const MOVE_CARICO_URL = new URL( 'move_carico/',  CARICO_URL )

export const LOGIN_URL = new URL( 'login/',  BASE_URL )
export const LOGOUT_URL = new URL( 'account/logout/',  BASE_URL )
export const REFRESH_URL = new URL( 'token/refresh',  BASE_URL )


export const MIDIUM_COL = [
  "Cod. magazzino",
  "cod. magazzino",
  "data",
  "dimensione",
  "trattamento",
  "famiglia",
  "famiglia prodotto",
  "cod. prodotto",
  "Cod. prodotto",
  "ordine",
  "ora",
  "stato",
]

export const SMALL_COL = [
  "numero cavalletto",
  "ubicazione scaffale",
  "Unità di misura",
  "scelta",
  "unità di misura",
  "N° pelli",
  "Tot. dimensione",
  "commessa",
  "segno",
  "giacenza",
]

export const ROUNDED_COL = [
  "dimensione",
  "Tot. dimensione"
]

export const NOT_SHOW_COL = [
  'id',
  'carico_id',
]
