import { useEffect, useRef, useState } from 'react'
import { useForm } from '../../Hooks'
import Select from '../input/Select'
import TextInput from '../input/TextInput'
import SearchInput from '../input/SearchInput'
import CaricoModal from './CaricoModal'
import DigitInput from '../input/DigitInput'
import CheckBox from '../input/CheckBox'

const CaricoForm = ({ formValues, setFormValuesByEvent, setFormValuesByName, selectedParent }) => {
  const initialRef = useRef(true)

  const [semaphore, setSemaphore] = useState({
    magazzino_id: false,
    trattamento_id: false
  })

  // useEffect(() => {
  //   if (Object.values(semaphore).every(bool => bool) && initialRef.current === true && values.id !== '') {
  //     initialRef.current = false
  //     setFormValues(values)
  //   }
  // }, [values, semaphore])

  // useEffect(() => {
  //   setValues(formValues)
  // }, [formValues])

  return (

    <form
      className='flex flex-wrap gap-3 overflow-y-auto h-full'
    >
      <Select
        name='magazzino_id'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        setSemaphore={setSemaphore}
      />

      <SearchInput
        name='anagrafica_cod_pr'
        formValues={formValues}
        setValue={(value) => setFormValuesByName('anagrafica_cod_pr', value)}
        setValueByEvent={setFormValuesByEvent}
        withDropdown
        label='Anagrafica Cod. Pr.'
        uppercase={true}
      />



      <div
        className="flex flex-col gap-4 justify-center items-start"
      >




      <DigitInput
        name='n_pelli'
        formValues={formValues}
        setValue={(event) => {
          // if (event.target.value < formValues.pelli.length) return
          setFormValuesByEvent(event)
        }}
        placeholder='N° pelli'
        max={50}
        min={0}
        disabled={formValues.pelliDisabled}
      />
      <CheckBox
        name='pelliDisabled'
        formValues={formValues}
        label="modifica pelli"
        setValue={ (value) => setFormValuesByName("pelliDisabled", value)}
        reverse={true}
      />

      </div>
      <Select
        name='trattamento_id'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        setSemaphore={setSemaphore}
      />

      <DigitInput
        name='commessa'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        placeholder='commessa'
      />
      <TextInput
        name='ordine'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        placeholder='Ordine'
      />
      <TextInput
        name='ubicazione_scaffale'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        placeholder='Ubicazione scaffale'
      />
      <DigitInput
        name='numero_cavalletto'
        formValues={formValues}
        setValue={setFormValuesByEvent}
        placeholder='Numero cavalletto'
      />

    </form>
  )
}

export default CaricoForm

// <Select
//   name='scelta_id'
//   formValues={formValues}
//   setValue={setFormValuesByEvent}
//   setSemaphore={setSemaphore}
// />
// <TextInput
//   name='id_tag'
//   formValues={formValues}
//   setValue={setFormValuesByEvent}
//   placeholder='ID Tag'
// />
