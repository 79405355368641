import { useEffect, useRef, useState, useTransition } from 'react'
import { useGet, useModelData } from '../../Hooks'
import SearchDropdown from './SearchDropdown'
import TextInput from './TextInput'
import { IoMdSearch } from 'react-icons/io'

const SearchInput = ({
  formValues,
  setValue,
  setValueByEvent,
  name,
  placeholder = 'Cerca',
  withDropdown = false,
  uppercase = false,
  label = null
}) => {
  const modelData = useModelData(name)

  const [isPending, startTransition] = useTransition()
  const [items, setItems] = useState([])

  const [showDropdown, setShowDropdown] = useState(false)

  const [getData, setGetData] = useState({ url: null })
  const getState = useGet(getData)

  useEffect(() => {
    if (
      withDropdown === true &&
      modelData.url != null &&
        formValues[name] != ''
    ) {
      const params = new URLSearchParams()
      params.set('search', formValues[name])
      const url = new URL(modelData.url)
      url.search = params.toString()

      setGetData({ url })
    }
  }, [withDropdown, name, modelData.url, formValues[name]])

  useEffect(() => {
    if (getState.data != null && formValues[name] != '') {
      startTransition(() => setItems(getState.data))
    } else {
      setItems([])
    }
  }, [getState.data, formValues[name], startTransition])

  const itemCallback = (obj) => {
    if (modelData.type === 'anagrafica') {
      setValue(obj['cod. prodotto'])
      // setTimeout(() => setShowDropdown(false), 2000)
    }
  }

  return (

    <div
      className='relative  w-full min-w-[200px] sm:max-w-[40%]  md:max-w-[30%]  lg:max-w-[300px] '
      onFocus={() => {
        setShowDropdown(true)
      }}
      onBlur={() => {
        setTimeout(() => setShowDropdown(false), 200)

      }}
    >

      <TextInput
        formValues={formValues}
        setValue={setValueByEvent}
        placeholder={placeholder}
        name={name}
        label={label}
        isSearch={true}
        isLoading={getState.loading || isPending } 
        uppercase={uppercase}
      />


      {
        withDropdown === true &&
          items.length > 0 &&
          showDropdown === true &&
            <SearchDropdown
              items={items}
              type={modelData.type}
              itemCallback={itemCallback}
            />
      }

    </div>

  )
}

export default SearchInput
