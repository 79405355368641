const TextArea = ({
  name,
  formValues,
  setValue,
  placeholder,
  label = null,
  propErrors = {},
  disabled = false
}) => {
  return (

    <div className='relative w-full px-4 min-w-[200px]  lg:max-w-[300px] '>
      <label className='mb-[3px] block text-sm text-blue-800 text-start '>
        {label != null ? label : placeholder}
      </label>

      <textarea
        className='w-full bg-text-bg rounded-md border border-stroke
                  border-blue-800
                  dark:border-dark-3 py-1 px-2 text-dark-6
                  outline-none transition active:border-primary
                  disabled:cursor-default disabled:bg-gray-2
                  focus:border-amber-500 resize-none'

        name={name}
        value={formValues[name]}
        onChange={event => setValue(event)}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete="false"
        maxLength={50}

      />
    </div>
  )
}

export default TextArea
