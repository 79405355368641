import { useEffect, useMemo, useState, useTransition } from "react";
import { PELLE_URL } from "../Costants";
import { useGet } from "../Hooks";
import Button from "../views/button/Button";
import InventarioFilter from "../views/inventario/InventarioFilter";
import Table from "../views/table/Table";
import InventarioModal from "../views/inventario/InventarioModal";
import Loader from "../views/loader/Loader";

const InventarioPage = () => {
  const [isPending, startTransition] = useTransition();

  const [showAnModal, setShowAnModal] = useState(false);
  const [action, setAction] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [getData, setGetData] = useState({ url: null });
  const getState = useGet(getData);

  const exportExcel = async () => {
    const getSearch = getData.url.search;
    const url = new URL("export/", PELLE_URL);
    url.search = getSearch;
    const request = new Request(url, {
      method: "GET",
      credentials: "include",
    });
    const response = await fetch(request);
    const contentDisposition = response.headers.get("content-type");
    const blob = await response.blob();
    const matches =
      contentDisposition && contentDisposition.match(/filename="(.*)"/);
    const fileName = matches && matches[1] ? matches[1] : "download.xlsx";
    const blobUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(blobUrl);
  };

  const filterCallback = (values) => {
    const params = new URLSearchParams();
    for (const key of Object.keys(values)) {
      if (values[key] != null && values[key].toString().trim() !== "") {
        params.append(key, values[key]);
      }
    }
    const url = new URL(PELLE_URL);
    url.search = params.toString();
    setGetData({ url });
  };

  const rowCallback = (some) => {
    setSelectedItem(some);
    setShowAnModal(true);
  };

  const respCallback = (obj) => {
    if (typeof obj === "object") {
      if (action === "create") {
        // Create new
        setItems((old) => {
          old.push(obj);
          return [...old];
        });
      } else {
        // Update
        setItems((old) => {
          let oldIdx = 0;
          const newItems = old.filter((ele) => {
            if (ele.id == obj.id) {
              oldIdx = old.indexOf(ele);
              return false;
            }
            return true;
          });
          newItems.splice(oldIdx, 0, obj);

          return [...newItems];
        });
      }
    } else {
      // Delete
      setItems((old) => {
        const newItems = old.filter((ele) => ele.id !== obj);
        return [...newItems];
      });
    }

    setAction(null);
    setShowAnModal(false);
  };

  useEffect(() => {
    setGetData({ url: PELLE_URL });
    return () => {
      setGetData({ url: null });
    };
  }, []);

  useEffect(() => {
    if (getState.data != null) {
      startTransition(() => {
        setItems(getState.data);
      });
    }
  }, [getState.data]);

  const renderContent = useMemo(() => {
    if (getState.loading === true || isPending === true) {
      return <Loader />;
    } else {
      return (
        <div className="mb-4">
          <Table items={items} rowCallback={rowCallback} />
        </div>
      );
    }
  }, [items, isPending, getState.loading]);

  return (
    <main className="flex flex-col items-center justify-center">
      <InventarioFilter
        pelliLen={items.length}
        filterCallback={filterCallback}
      />

      {renderContent}

      <Button  text="Esporta file excel" onclick={exportExcel} />

      {showAnModal === true && (
        <InventarioModal
          showHandler={(show) => {
            setAction(null);
            setShowAnModal(show);
          }}
          item={selectedItem ?? 0}
          respCallback={respCallback}
          action={action}
        />
      )}
    </main>
  );
};

export default InventarioPage;
