import Button from "../button/Button"
import Modal from "./Modal"




const InfoModal = ({ title, text, closeModal,  btnText }) =>{


  return (
    <Modal
      showHadler={closeModal}
    >


      <div className="flex flex-col gap-7">



        <h2
          className="text-xl"
        >{title}</h2>

        <p>{text}</p>

        <div className="flex w-full justify-around">


          <Button 
            text={btnText}
            onclick={closeModal}
          />

        </div>
      </div>

    </Modal>
  )
}

export default InfoModal
